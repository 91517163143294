// React and hooks
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// MUI Components
import {
    Paper,
    Tooltip,
    Typography,
    Autocomplete,
    Box,
    Checkbox,
    TextField,
    Popper
} from '@mui/material';
import Chip from '@material-ui/core/Chip';

// MUI Icons
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

// Components
import Label from './Label';

// Constants
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const chipStyles = {
    chip: {
        height: '17px !important',
        padding: '0 5px !important',
        fontSize: '8px !important',
        minHeight: '17px !important',
        lineHeight: '0.9 !important'
    },
    label: {
        fontFamily: 'Lato',
        cursor: 'pointer'
    },
    counter: {
        backgroundColor: '#6c757d',
        color: 'white',
        padding: '0 5px',
        borderRadius: '3px',
        marginLeft: '3px',
        fontWeight: 'bold'
    }
};

/**
 * Autocomplete component with support for single/multiple selection,
 *
 * @component
 * @param {Object} props - Component props
 * @param {Array|Object|string} props.value - Selected value(s)
 * @param {Function} props.onChange - Callback when selection changes
 * @param {Array} props.options - Array of options to select from
 * @param {Function} props.onInputChange - Callback when input value changes
 * @param {boolean} props.loading - Loading state
 * @param {string} props.label - Label text
 * @param {boolean} props.multiple - Enable multiple selection
 * @param {string} props.idField - Field to use as ID (default: 'id')
 * @param {string} props.nameField - Field to use as display name (default: 'label')
 * @param {string} props.placeholder - Input placeholder text
 * @param {('small'|'medium'|'large')} props.size - Component size
 * @param {boolean} props.disabled - Disable the component
 * @param {boolean} props.required - Mark as required
 * @param {boolean} props.error - Show error state
 * @param {string} props.helperText - Helper text below input
 * @param {Function} props.renderOption - Custom option rendering
 * @param {Function} props.getOptionLabel - Custom option label getter
 * @param {Function} props.isOptionEqualToValue - Custom option comparison
 * @param {Function} props.onOpen - Callback when dropdown opens
 * 
 * @example
 *        <AutoCompleteSearch
 *           value=[value]
 *           options={options}
 *           onInputChange={handleInputChange}
 *           loading={loading}
 *           onChange={handleChange}
 *           multiple
 *           label={commonProps?.label || "Search"}
 *           placeholder={commonProps?.placeholder || ""}
 *           onOpen={onOpen}
 *       />
 */
export default function AutoCompleteSearch({
    value,
    onChange,
    options,
    onInputChange,
    loading,
    label,
    multiple = true,
    idField = 'id',
    nameField = 'label',
    placeholder = 'Search...',
    size = 'medium',
    disabled = false,
    required = false,
    error = false,
    helperText = '',
    renderOption = null,
    getOptionLabel = null,
    isOptionEqualToValue = null,
    onOpen,
    disableCloseOnSelect,
    ...props
}) {
    /**
     * State for focus and hover tracking
     */
    const [isFocused, setIsFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    /**
     * Default function to get the display label for an option
     * @param {Object|string} option - Option to get label from
     * @returns {string} Display label
     */
    const defaultGetOptionLabel = (option) => {
        if (typeof option === 'string') return option;
        return option?.[nameField] || '';
    };

    /**
     * Default function to compare options for equality
     * @param {Object} option - Option to compare
     * @param {Object} value - Value to compare against
     * @returns {boolean} Whether the option equals the value
     */
    const defaultIsOptionEqualToValue = (option, value) => {
        return option?.[idField] === value?.[idField];
    };

    return (
        <Tooltip
            title={
                <Box>
                    {multiple && value?.map((item, i) => (
                        <Typography key={i} variant="body2">
                            {item[nameField]}
                        </Typography>
                    ))}
                </Box>
            }
            arrow
            placement="right"
            open={(isFocused || isHovered) && multiple && value?.length > 0}
        >
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {label && <Label label={label} required={required} />}
                <Autocomplete
                    disableCloseOnSelect={disableCloseOnSelect !== undefined ? disableCloseOnSelect : true}
                    openOnFocus={true}
                    onOpen={onOpen}
                    multiple={multiple}
                    options={options}
                    value={value}
                    onChange={onChange}
                    onInputChange={onInputChange}
                    loading={loading}
                    disabled={disabled}
                    getOptionLabel={getOptionLabel || defaultGetOptionLabel}
                    isOptionEqualToValue={isOptionEqualToValue || defaultIsOptionEqualToValue}
                    renderOption={renderOption || ((props, option, { selected }) => (
                        <li {...props}>
                            {multiple && (
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                            )}
                            {option[nameField]}
                        </li>
                    ))}
                    renderTags={(value, getTagProps) =>
                        value.slice(0, 1).map((option, index) => (
                            <Chip
                                key={index}
                                sx={chipStyles.chip}
                                {...getTagProps({ index })}
                                label={
                                    <span style={chipStyles.label}>
                                        {option[nameField]}
                                        {value.length > 1 && (
                                            <span style={chipStyles.counter}>
                                                +{value.length - 1}
                                            </span>
                                        )}
                                    </span>
                                }
                                deleteIcon={<></>}
                                size="small"
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder}
                            error={error}
                            helperText={helperText}
                            size={size}
                            required={required}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '37px',
                                    padding: '0 !important',
                                    paddingLeft: '10px !important',
                                    '& fieldset': {
                                        borderColor: error ? '#EC5F60 !important' : '#BDBDBD',
                                        borderWidth: '1px !important'
                                    },
                                    '&:hover fieldset': {
                                        borderColor: error ? '#EC5F60 !important' : '#1976d2',
                                        borderWidth: '1px !important'
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: error ? '#EC5F60 !important' : '#1976d2',
                                        borderWidth: '1px !important'
                                    }
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: error ? '1px solid #EC5F60 !important' : '1px solid #BDBDBD',
                                    top: 0
                                },
                                '& .MuiOutlinedInput-notchedOutline legend': {
                                    display: "none"
                                },
                                '& .MuiInputLabel-outlined': {
                                    transform: 'translate(14px, 8px) scale(1)'
                                },
                                '& .MuiAutocomplete-endAdornment': {
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    right: '9px',
                                    zIndex: 2,
                                },
                                '& .MuiAutocomplete-clearIndicator': {
                                    '& .MuiSvgIcon-root': {
                                        fontWeight: 'bold',
                                        width: '20px',
                                        height: '20px'
                                    }
                                }
                            }}
                        />
                    )}
                    PopperComponent={({ children, ...props }) => (
                        <Popper {...props} placement="bottom-start">
                            <Paper elevation={4}>
                                {children}
                            </Paper>
                        </Popper>
                    )}
                    {...props}
                />
            </div>
        </Tooltip>
    );
}

AutoCompleteSearch.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string
    ]),
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    onInputChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    idField: PropTypes.string,
    nameField: PropTypes.string,
    placeholder: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    renderOption: PropTypes.func,
    getOptionLabel: PropTypes.func,
    isOptionEqualToValue: PropTypes.func,
};
import React,{useEffect, useMemo, useState} from 'react';
import { useSelector } from 'react-redux';
import Notify from '../commons/notify';
import { Form } from 'react-bootstrap';
import CommonButton from '../commons/Buttons';
import i18n from '../../utilities/i18n';
import SelectInput from '../commons/input/SelectInput';
import { Divider } from '@mui/material';
import { DataForICD } from '../../utilities/dictionaryConstants';
import AsyncTypeInput from '../commons/input/AsyncTypeHead/AsyncTypeInput';
import Procedures from './Procedure';
import { useDispatch } from 'react-redux';
import { GetICDList } from './StateManagement/DropDownOptionSlice';
import { checkPermission } from '../../utilities/commonUtilities';
import { permission_key_values_claim } from '../../utilities/permissions';
import { addNewProcedureRow, HandleICDChange, ICDTypeChange, showModalWindow } from './StateManagement/ClaimModuleSlice';
import { getChargePanelsList, getDiagnosisCodeOnPaste, getPatientHistory } from './StateManagement/asyncThunkAPI';
import CustomizedDialogs from '../modalWindowComponent/CustomizedDialogs';
import PatientHistory from './PatientHistory/PatientHistory';
import ClaimChargePanel from './ClaimChargePanel/ClaimChargePanel';
 const debounce = (func, delay) => {
     let timeoutId;
     return function (input) {
         clearTimeout(timeoutId);
         timeoutId = setTimeout(() => func(input), delay);
     };
 };

const ServiceLine = () => {
    const dispatch = useDispatch();
        const {
            ICDLIST
    } = useSelector((state) => state.claimSelectInputManager);
    const {
        serviceLineDetails,
        diagnosisValidation,
        claimPK,
        professionalClaimDetails,
        patientHistoryModal,
        insuranceCompleted,
    } = useSelector((state) => state.claimManagement);

    // notify
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    // eslint-disable-next-line
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    /**
     * if procedure have no cpt resposibility type will set from here
     * it will happen when ever a patient created with no cpt 
     */
    const paymentDone = serviceLineDetails?.procedures?.some(procedure => procedure.payment_done);
    useEffect(() => {
        if (serviceLineDetails?.procedures?.length == 0 && insuranceCompleted) {
            
            dispatch(addNewProcedureRow({ claimPK: claimPK, paymentDone:paymentDone}));
        }
    }, [insuranceCompleted]);


    /**
     * function used to store select input values
     * @param {*} e
     */
    const onHandleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        dispatch(
            ICDTypeChange({ field: name, value: value, claimPK: claimPK })
        );
    };

    /**
     * The function used to store selected diagnosis form the list of search
     * @param {*} e
     * @param {*} diagnosis
     */
    const onHandleICDChange = (e, diagnosis) => {
        dispatch(
            HandleICDChange({
                field: diagnosis,
                valueList: e.length > 0 ? e : [],
                claimPK: claimPK,
            })
        );
    };

    /**
     * It is a function used to call diagnosis list while typing
     * @param {*} query
     */
    const onSearchICD10 = debounce((query) => {
        dispatch(GetICDList({ query: query, claimPK: claimPK }));
    }, 250);

    /**
     * diagnosis on paste will rendered here
     * @param {*} e
     * @param {*} diagnosis
     * @param {*} type
     */
    const onBlur = (e, diagnosis, searchType) => {
        let inputValue;
        let type = 'dropdown'
        if (searchType == "DIAGNOSISCODE") {
            inputValue = e?.target?.value?.toUpperCase() ?? e?.toUpperCase();

            dispatch(
                getDiagnosisCodeOnPaste({
                    value: inputValue,
                    type: type,
                    claimPK: claimPK,
                    diagnosis: diagnosis,
                })
            );
        }
    };

    const onAddProcedure = () => {
        dispatch(addNewProcedureRow({ claimPK: claimPK, paymentDone:paymentDone}));
    };


    const handleChargePanelBtnClick = () => {
        if (checkPermission(permission_key_values_claim.claims_charge_panel_view)) {
            dispatch(getChargePanelsList())
        }
        else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
        }
    }


    return (
        <div className="col-md-12 margin-top20">
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <Form>
                <div className="row pb-2">
                    <div className="col-2 padding-left20">
                        <SelectInput
                            data={DataForICD}
                            name="icd"
                            id="icd-type-select"
                            value={serviceLineDetails?.icd}
                            onValueChange={(e) => onHandleChange(e)}
                            label={i18n.t(
                                "searchClaims.professionalClaims.serviceLine.icd"
                            )}
                        />
                    </div>
                    <div className="col-4 padding-top25 pl-0 d-flex justify-content-start">
                        {professionalClaimDetails?.patient && (
                            <div className="mr-2">
                                <CommonButton
                                    id="patient-history-btn"
                                    variant="outlined"
                                    label={i18n.t(
                                        "searchClaims.professionalClaims.serviceLine.patientHistory"
                                    )}
                                    onClick={() =>
                                        dispatch(getPatientHistory(professionalClaimDetails?.patient))
                                    }
                                />
                            </div>
                        )}

                        {professionalClaimDetails?.patient && (
                            <CommonButton
                                id="charge-panel-modal-btn"
                                variant="outlined"
                                label={"Charge Panel"}
                                onClick={handleChargePanelBtnClick}
                            />
                        )}
                    </div>
                </div>
                <div className="row padding-left17">
                    <div className="div-inline">
                        <label className="margin-top-3px">
                            {i18n.t(
                                "searchClaims.professionalClaims.serviceLine.diagnosisCodes"
                            )}
                        </label>
                    </div>
                </div>
                <Divider
                    style={{ color: "#000", marginTop: 2, marginBottom: 20 }}
                />
                <div className="row padding-left7 margin-bottom-12">
                    <div className="col-2">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={
                                    diagnosisValidation.diagnosis1
                                        ? diagnosisValidation.diagnosis1
                                        : ""
                                }
                                labelKey="name"
                                name="diagnosis1"
                                minLength={1}
                                onValueChange={(e) =>
                                    onHandleICDChange(e, "diagnosis1")
                                }
                                options={ICDLIST}
                                functionInvoke={true}
                                onBlur={(e) =>
                                    onBlur(e, "diagnosis1", "DIAGNOSISCODE")
                                }
                                onSearch={onSearchICD10}
                                selected={serviceLineDetails?.diagnosis1List}
                                id={"diagnosis1_input"}
                                label={i18n.t(
                                    "searchClaims.professionalClaims.serviceLine.a"
                                )}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={
                                    diagnosisValidation.diagnosis2
                                        ? diagnosisValidation.diagnosis2
                                        : ""
                                }
                                labelKey="name"
                                name="diagnosis2"
                                minLength={1}
                                onValueChange={(e) =>
                                    onHandleICDChange(e, "diagnosis2")
                                }
                                options={ICDLIST}
                                functionInvoke={true}
                                onBlur={(e) =>
                                    onBlur(e, "diagnosis2", "DIAGNOSISCODE")
                                }
                                onSearch={onSearchICD10}
                                selected={serviceLineDetails?.diagnosis2List}
                                id={"diagnosis2_input"}
                                label={i18n.t(
                                    "searchClaims.professionalClaims.serviceLine.b"
                                )}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={
                                    diagnosisValidation.diagnosis3
                                        ? diagnosisValidation.diagnosis3
                                        : ""
                                }
                                labelKey="name"
                                name="diagnosis3"
                                minLength={1}
                                onValueChange={(e) =>
                                    onHandleICDChange(e, "diagnosis3")
                                }
                                options={ICDLIST}
                                functionInvoke={true}
                                onBlur={(e) =>
                                    onBlur(e, "diagnosis3", "DIAGNOSISCODE")
                                }
                                onSearch={onSearchICD10}
                                selected={serviceLineDetails?.diagnosis3List}
                                id={"diagnosis3_input"}
                                label={i18n.t(
                                    "searchClaims.professionalClaims.serviceLine.c"
                                )}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={
                                    diagnosisValidation.diagnosis4
                                        ? diagnosisValidation.diagnosis4
                                        : ""
                                }
                                labelKey="name"
                                name="diagnosis4"
                                minLength={1}
                                onValueChange={(e) =>
                                    onHandleICDChange(e, "diagnosis4")
                                }
                                options={ICDLIST}
                                functionInvoke={true}
                                onBlur={(e) =>
                                    onBlur(e, "diagnosis4", "DIAGNOSISCODE")
                                }
                                onSearch={onSearchICD10}
                                selected={serviceLineDetails?.diagnosis4List}
                                id={"diagnosis4_input"}
                                label={i18n.t(
                                    "searchClaims.professionalClaims.serviceLine.d"
                                )}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={
                                    diagnosisValidation.diagnosis5
                                        ? diagnosisValidation.diagnosis5
                                        : ""
                                }
                                labelKey="name"
                                name="diagnosis5"
                                minLength={1}
                                onValueChange={(e) =>
                                    onHandleICDChange(e, "diagnosis5")
                                }
                                options={ICDLIST}
                                functionInvoke={true}
                                onBlur={(e) =>
                                    onBlur(e, "diagnosis5", "DIAGNOSISCODE")
                                }
                                onSearch={onSearchICD10}
                                selected={serviceLineDetails?.diagnosis5List}
                                id={"diagnosis5_input"}
                                label={i18n.t(
                                    "searchClaims.professionalClaims.serviceLine.e"
                                )}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={
                                    diagnosisValidation.diagnosis6
                                        ? diagnosisValidation.diagnosis6
                                        : ""
                                }
                                labelKey="name"
                                name="diagnosis6"
                                minLength={1}
                                onValueChange={(e) =>
                                    onHandleICDChange(e, "diagnosis6")
                                }
                                options={ICDLIST}
                                functionInvoke={true}
                                onBlur={(e) =>
                                    onBlur(e, "diagnosis6", "DIAGNOSISCODE")
                                }
                                onSearch={onSearchICD10}
                                selected={serviceLineDetails?.diagnosis6List}
                                id={"diagnosis6_input"}
                                label={i18n.t(
                                    "searchClaims.professionalClaims.serviceLine.f"
                                )}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="row pb-2 padding-left7 margin-bottom-12">
                    <div className="col-2">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={
                                    diagnosisValidation.diagnosis7
                                        ? diagnosisValidation.diagnosis7
                                        : ""
                                }
                                labelKey="name"
                                name="diagnosis7"
                                minLength={1}
                                onValueChange={(e) =>
                                    onHandleICDChange(e, "diagnosis7")
                                }
                                options={ICDLIST}
                                functionInvoke={true}
                                onBlur={(e) =>
                                    onBlur(e, "diagnosis7", "DIAGNOSISCODE")
                                }
                                onSearch={onSearchICD10}
                                selected={serviceLineDetails?.diagnosis7List}
                                id={"diagnosis7_input"}
                                label={i18n.t(
                                    "searchClaims.professionalClaims.serviceLine.g"
                                )}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={
                                    diagnosisValidation.diagnosis8
                                        ? diagnosisValidation.diagnosis8
                                        : ""
                                }
                                labelKey="name"
                                name="diagnosis8"
                                minLength={1}
                                onValueChange={(e) =>
                                    onHandleICDChange(e, "diagnosis8")
                                }
                                options={ICDLIST}
                                functionInvoke={true}
                                onBlur={(e) =>
                                    onBlur(e, "diagnosis8", "DIAGNOSISCODE")
                                }
                                onSearch={onSearchICD10}
                                selected={serviceLineDetails?.diagnosis8List}
                                id={"diagnosis8_input"}
                                label={i18n.t(
                                    "searchClaims.professionalClaims.serviceLine.h"
                                )}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={
                                    diagnosisValidation.diagnosis9
                                        ? diagnosisValidation.diagnosis9
                                        : ""
                                }
                                labelKey="name"
                                name="diagnosis9"
                                minLength={1}
                                onValueChange={(e) =>
                                    onHandleICDChange(e, "diagnosis9")
                                }
                                options={ICDLIST}
                                functionInvoke={true}
                                onBlur={(e) =>
                                    onBlur(e, "diagnosis9", "DIAGNOSISCODE")
                                }
                                onSearch={onSearchICD10}
                                selected={serviceLineDetails?.diagnosis9List}
                                id={"diagnosis9_input"}
                                label={i18n.t(
                                    "searchClaims.professionalClaims.serviceLine.i"
                                )}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={
                                    diagnosisValidation.diagnosis10
                                        ? diagnosisValidation.diagnosis10
                                        : ""
                                }
                                labelKey="name"
                                name="diagnosis10"
                                minLength={1}
                                onValueChange={(e) =>
                                    onHandleICDChange(e, "diagnosis10")
                                }
                                options={ICDLIST}
                                functionInvoke={true}
                                onBlur={(e) =>
                                    onBlur(e, "diagnosis10", "DIAGNOSISCODE")
                                }
                                onSearch={onSearchICD10}
                                selected={serviceLineDetails?.diagnosis10List}
                                id={"diagnosis10_input"}
                                label={i18n.t(
                                    "searchClaims.professionalClaims.serviceLine.j"
                                )}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                class_name={
                                    diagnosisValidation.diagnosis11
                                        ? diagnosisValidation.diagnosis11
                                        : ""
                                }
                                labelKey="name"
                                name="diagnosis11"
                                minLength={1}
                                onValueChange={(e) =>
                                    onHandleICDChange(e, "diagnosis11")
                                }
                                options={ICDLIST}
                                functionInvoke={true}
                                onBlur={(e) =>
                                    onBlur(e, "diagnosis11", "DIAGNOSISCODE")
                                }
                                onSearch={onSearchICD10}
                                selected={serviceLineDetails?.diagnosis11List}
                                id={"diagnosis11_input"}
                                label={i18n.t(
                                    "searchClaims.professionalClaims.serviceLine.k"
                                )}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                    <div className="col-2 pl-0">
                        <div className="margin-top-16">
                            <AsyncTypeInput
                                labelKey="name"
                                name="diagnosis12"
                                minLength={1}
                                onValueChange={(e) =>
                                    onHandleICDChange(e, "diagnosis12")
                                }
                                options={ICDLIST}
                                functionInvoke={true}
                                onBlur={(e) =>
                                    onBlur(e, "diagnosis12", "DIAGNOSISCODE")
                                }
                                onSearch={onSearchICD10}
                                selected={serviceLineDetails?.diagnosis12List}
                                id={"diagnosis12_input"}
                                label={i18n.t(
                                    "searchClaims.professionalClaims.serviceLine.l"
                                )}
                                isMenuToggleCallbackNeeded={true}
                            />
                        </div>
                    </div>
                </div>

                <div className="row padding-left17">
                    <div className="div-inline">
                        <label className="margin-top-3px padding-left2">
                            {i18n.t(
                                "searchClaims.professionalClaims.serviceLine.procedures"
                            )}
                        </label>
                    </div>
                </div>
                <Divider
                    style={{ color: "#000", marginTop: 2, marginBottom: 20 }}
                />
                <Form>
                    <div>
                        {useMemo(
                            () =>
                                serviceLineDetails?.procedures?.map(
                                    (item, index) => {
                                        return (
                                            <div key={index}>
                                                <Procedures
                                                    procedure={item}
                                                    index={index}
                                                    diagnosisVariable={
                                                        serviceLineDetails
                                                    }
                                                />
                                            </div>
                                        );
                                    }
                                ),
                            [serviceLineDetails?.procedures]
                        )}
                    </div>
                    {professionalClaimDetails?.claim_editable &&
                        serviceLineDetails?.procedures?.length &&
                        checkPermission(
                            permission_key_values_claim.search_claims_sub_module_modify,
                            permission_key_values_claim.claims_search_claims_add
                        ) ? (
                        <div
                            className="justify-right"
                            style={{ position: "relative", top: "10px" }}>
                            <CommonButton
                                onClick={onAddProcedure}
                                variant="contained"
                                icon="add"
                                label={i18n.t("searchClaims.addProcedure")}
                            />
                        </div>
                    ) :
                        <></>
                    }
                    {
                        professionalClaimDetails?.claim_editable &&
                            !serviceLineDetails?.procedures?.length &&
                            checkPermission(
                                permission_key_values_claim.search_claims_sub_module_modify,
                                permission_key_values_claim.claims_search_claims_add
                            ) ?
                            <div style={{ width: "100%", textAlign: "center", fontSize: '18px' }}>
                                <p>Please add Patient & Procedures to Continue.</p>
                            </div>
                            :
                            <></>
                    }
                </Form>
            </Form>
            <CustomizedDialogs
                type="patienthistory"
                header={i18n.t(
                    "searchClaims.professionalClaims.serviceLine.patientHistory"
                )}
                showModal={patientHistoryModal}
                setShowModalWindow={() =>
                    dispatch(
                        showModalWindow({
                            key: "patientHistoryModal",
                            value: false,
                            claimPK: "",
                        })
                    )
                }>
                <PatientHistory />
            </CustomizedDialogs>

            <ClaimChargePanel />
        </div>
    );
}

export default ServiceLine;
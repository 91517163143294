// React and hooks
import React, { useState, useCallback, useRef, useMemo, memo } from 'react';

// Third-party libraries
import { debounce } from 'lodash';

// Components
import AutoCompleteSearch from '../../commons/AutoCompleteSearch';

// Services
import patientService from '../../patientManagement/patients/service';
import reportFilterService from '../service';

// Utilities
import { getStorage } from '../../../utilities/browserStorage';

/**
 * Service configuration map for different dropdown types
 * @type {Object.<string, {service: Function, params: Function}>}
 */
const serviceMap = {
    patientIdList: {
        service: patientService.GetListPatientDropdown,
        params: (query) => query
    },
    adjustmentCodesList: {
        service: reportFilterService.GetAdjustmentCodes,
        params: (query) => [query, 0, 0, getStorage('practice')]
    }
};




/**
 * A memoized component that provides searchable select functionality with async data fetching
 * 
 * @component
 * @param {Object} props - Component props
 * @param {Object} props.commonProps - Common properties for the AutoCompleteSearch component
 * @param {Array} props.commonProps.value - Currently selected values
 * @param {string} props.commonProps.idField - Field to use as ID (default: 'id')
 * @param {Function} props.handleValueChange - Callback function when value changes
 * @param {string} props.dropDownOptionName - Key to identify which service to use from serviceMap
 * 
 * @example
 * <SearchSelectFilter
 *   commonProps={{
 *     value: selectedPatients,
 *     id: 'patientId',
 *     label: 'Select Patients'
 *   }}
 *   handleValueChange={handlePatientChange}
 *   dropDownOptionName="patientIdList"
 * />
 * 
 * @returns {React.ReactElement} Rendered SearchSelectFilter component
 */
const SearchSelectFilter = memo(function SearchSelectFilter({
    commonProps,
    handleValueChange,
    dropDownOptionName
}) {
    const [loading, setLoading] = useState(false);
    const [, forceUpdate] = useState({});
    const optionsRef = useRef([]);

    // Cache options with useMemo
    const options = useMemo(() => optionsRef.current, [optionsRef.current]);

    const searchService = serviceMap[dropDownOptionName];

    if (!searchService) {
        console.error(`No search service found for ${dropDownOptionName}`);
        return null;
    }



    const debouncedSearch = useCallback(
        debounce(async (query) => {
            if (query.length >= 3) {
                setLoading(true);
                try {
                    const serviceConfig = serviceMap[dropDownOptionName];
                    const params = serviceConfig.params(query);

                    // Handle both array params and single param
                    const response = Array.isArray(params)
                        ? await serviceConfig.service(...params)
                        : await serviceConfig.service(params);

                    const newOptions = response.data;
                    // retrieve only id and the label
                    const newOptionsWithIdAndLabel = newOptions.map(option => ({
                        id: option.id,
                        label: option.label
                    }));

                    const combinedOptions = [...newOptionsWithIdAndLabel];

                    commonProps.value?.forEach(selectedItem => {
                        if (!newOptions.find(option =>
                            option[commonProps?.idField || 'id'] === selectedItem[commonProps?.idField || 'id']
                        )) {
                            combinedOptions.push(selectedItem);
                        }
                    });

                    optionsRef.current = combinedOptions;
                } catch (error) {
                    console.error('Search error:', error);
                } finally {
                    setLoading(false);
                }
            }
        }, 300),
        [dropDownOptionName]
    );



    const handleInputChange = (event, newInputValue) => {
        debouncedSearch(newInputValue);
    };



    const handleChange = useCallback((event, newValue) => {
        handleValueChange?.(newValue);

        // Preserve existing options while adding new selections
        const currentOptions = [...optionsRef.current];
        newValue.forEach(selectedItem => {
            if (!currentOptions.find(option =>
                option[commonProps?.idField || 'id'] === selectedItem[commonProps?.idField || 'id']
            )) {
                currentOptions.push(selectedItem);
            }
        });

        optionsRef.current = currentOptions;
    }, [handleValueChange, commonProps?.idField]);



    const onOpen = () => {
        if (commonProps.value?.length) {
            optionsRef.current = commonProps.value;
            forceUpdate({});
        }
    }

    return (
        <AutoCompleteSearch
            {...commonProps}
            options={options}
            multiple={true}
            onInputChange={handleInputChange}
            loading={loading}
            onChange={handleChange}
            label={commonProps?.label || "Search"}
            placeholder={commonProps?.placeholder || ""}
            onOpen={onOpen}
        />
    );
});

export default SearchSelectFilter;

// React core imports
import React, { useEffect } from 'react';

// Redux related imports
import { useDispatch, useSelector } from 'react-redux';
import { showModalWindow } from '../StateManagement/ClaimModuleSlice';
import { addNewProcedureFromChargePanel } from '../StateManagement/asyncThunkAPI';

// Local component imports
import { ClaimChargePanelsTable } from './claimChargePanelTable';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import Table from '../../commons/Table/Table';

// Utility imports
import { commonTableBody } from '../../../utilities/commonUtilities';


/**
 * Claim Charge Panel Component
 * Renders a table of charge panels and a plus button
 * to help user add multiple procedures to the claim from the configured panel
 * @returns {React.Component}
 */
const ClaimChargePanel = () => {
    const dispatch = useDispatch();
    const { chargePanelsList, chargePanelsModal } = useSelector((state) => state.claimManagement);
    const initialTableBodyData = ClaimChargePanelsTable.tableBodyData;


    /**
     * Update the table on modal open
     */
    useEffect(() => {
        if (chargePanelsModal) {
            if (chargePanelsList?.length > 0) {
                const updatedRows = commonTableBody(
                    chargePanelsList,
                    initialTableBodyData[0]
                );

                ClaimChargePanelsTable.tableBodyData = updatedRows;
            } else {
                ClaimChargePanelsTable.tableBodyData = initialTableBodyData;
            }
        }
    }, [chargePanelsList, chargePanelsModal]);


    /**
     * Dispatch the addNewProcedureFromChargePanel redux action
     * @param {HTMLClickEvent} _ 
     * @param {string} id 
     */
    const handlePlusButtonClick = (_, id) => {
        dispatch(addNewProcedureFromChargePanel(id));
    };

    return (
        <CustomizedDialogs
            type="charge-panel"
            header="Charge Panel"
            showModal={chargePanelsModal}
            setShowModalWindow={() =>
                dispatch(
                    showModalWindow({
                        key: "chargePanelsModal",
                        value: false,
                        claimPK: "",
                    })
                )
            }
        >
            <Table
                tableObject={ClaimChargePanelsTable}
                onPlusButtonClick={handlePlusButtonClick}
            />
        </CustomizedDialogs>
    );
};

export default ClaimChargePanel;
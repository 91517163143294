import React, { useEffect,useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Notify from '../../commons/notify';
import i18n from '../../../utilities/i18n';
import * as staticConfigs from '../../../utilities/staticConfigs';
import { useLocation } from "react-router-dom";
import { headers } from '../headerComponent/menuItems';
import ReportHandle from '../headerComponent/ReportHandle';
import Typography from '@mui/material/Typography';

const PageTitle = () => {
    const dispatch = useDispatch();
    const pathName = useLocation().pathname;
    const [title, setTitle] = useState('');
    const permissionErrorFlag = useSelector((state) => state.commonStates.permissionErrorFlag);
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success")

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function getPageTitle() {
        if (window.location.pathname.indexOf((staticConfigs.ROUTE_PATIENTS_LIST + '/')) >= 0) {
            return i18n.t('menuNames.patients');
        }
        else {
            const pathname = window.location.pathname;
            const menuKey = headers[pathname];

            if (menuKey) {
                return i18n.t(menuKey);
            } else {
                return i18n.t('projectName')
            }
        }
    }

    // Update the page header whenever path name changes
    useEffect(() => {
        const setPageTitle = () => {
            if (window.claimInfo && pathName === "/logs/claim") {
                // When the user is redirected to the audit logs from the claim module,
                // modify the page title to include the claim number and patient name.
                const claimData = window.claimInfo;
                return `${getPageTitle()} Claim# ${claimData.custom_claimId}, ${claimData.patient_Name}`;
            }
            return getPageTitle();
        };

        setTitle(setPageTitle());
    }, [pathName])

    /**
   * RENDER NO-PERMISSION NOTIFY WHEN PERMISSION ERROR THROWS FROM THE SERVER
   * WHEN AN API HITS WITH 403 THEN FROM api.js FILE REDUX STATE permissionErrorFlag WILL BE SET TO true!
   */
    useEffect(() => {
        if (permissionErrorFlag) {
            showNotifyWindow(
                "show",
                "error",
                "Do not have sufficient permission to perform this action."
            );
            setTimeout(() => {
                dispatch({ type: "commonStates/setCommonState", payload: { name: "permissionErrorFlag", value: false } })
            }, 3000)
        }
    }, [permissionErrorFlag])


    return (
        <div>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyPrimaryText={"No Permission"}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
           <div>
                <Typography
                    id="headerTitle"
                    style={{
                        color: 'var(--grey-g-900, #212121)',
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: 'Lato',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal'
                    }}
                >
                    {title}
                </Typography>
            </div>
             {/**  The ReportHandle component serves as a functional component responsible for report generation. 
             * While it doesn't have any visible elements, it performs essential tasks such as listening for messages 
             * from the web-worker thread and dispatching actions to the Redux Report Slices. 
             * */}
            <ReportHandle />
        </div>
    )
}

export default PageTitle;



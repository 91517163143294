import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, Redirect, useHistory } from 'react-router-dom';
import format from 'date-fns/format';

import searchClaimService from '../services/claim_service';
import { PaperClaimsTableData, TableBodyData } from './PaperClaimsTable';

import LoadingContext from '../../../container/loadingContext';

import i18n from '../../../utilities/i18n';
import { checkPermission, commonTableBody, formatDateUS } from '../../../utilities/commonUtilities';
import { getStorage, setStorage } from '../../../utilities/browserStorage';
import { PAYER_TYPE_LIST, ICD, claimAmountType, ClaimPrintType, CLAIM_TYPES } from '../../../utilities/dictionaryConstants';
import { DEFAULT_PAGING_SIZE, PAGING_END_INDEX, ROUTE_PATIENTS_LIST, ROUTE_SEARCH_CLAIMS, MAX_OPEN_TABS_CLAIMS, MAX_OPEN_TABS_PATIENTS, ROUTE_INSURANCE_COMPANIES_EDIT } from '../../../utilities/staticConfigs';

import { MaterialMultiselect } from '../../../MaterialMultiselect/MaterialMultiselect';

import Notify from '../../commons/notify';
import Table from '../../commons/Table/Table';
import Pagination from '../../commons/pagination';
import SelectInput from '../../commons/input/SelectInput';
import CalendarInput from '../../commons/input/CalendarInput';
import RightSidePanel from '../../commons/RightSidePanel/RightSidePanel';

import PatientsAdvancedSearch from '../../patientManagement/patients/PatientsAdvancedSearch';
import PatientDropDownSearch from '../../patientManagement/patients/patientDropDownSearch';
import MaterialMultiSelectSearch from '../../../MaterialMultiselect/MaterialMultiSelectSearch';
import ExportIconButton from '../../commons/exportIconButton';
import CurrencyInputs from '../../commons/input/CurrencyInput';
import { permission_key_values_claim } from '../../../utilities/permissions';
import CommonButton from '../../commons/Buttons';
import { useDispatch,useSelector } from 'react-redux';
import { getFilterDataSet, getPaperclaimData, hasSelectedAll, selectEnteries, setCheckboxResetFlag, setError } from '../StateManagement/paperClaimSlice';

function PaperClaims() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { selectedEntries, hasCheckedAll, reseted, searched, orderType, claim_order_type,loading,tableData,count,pageSize,
        startIndex, endIndex, activePage, searchButtonClicked, previousQuery, patientSelectedData, patient_PK,checkboxResetflag

    } = useSelector((state) => state.paperclaim);
    const searchQueryInput = useSelector((state) => state.paperclaim.searchQuery);
    const sorting = useSelector((state) => state.paperclaim.sorting);
    const {dropDownListData,isErrorGettingDropdownData,isFilterDropDownDataReceived } = useSelector((state) => state.paperclaim);
    const practicePK = getStorage("practice")
    const paperClaimsTableOpened = getStorage('paperClaimsTableOpened');
    const [patientPK, setPatientPK] = useState("");
    const [totalPage, setTotalPage] = useState(1);
    const setShowLoadingBar = useContext(LoadingContext);
    const [paperClaimsData, setPaperClaimsData] = useState([]);
    const [patientSelected, setPatientSelected] = useState([]);
    const [checkedAllClaim, setCheckedAllClaim] = useState(false);
    const [claimType, setClaimType] = useState(1);
    const [tableUpdateFlag, setTableUpdateFlag] = useState(0);

    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const [redirectToPatient, setRedirectToPatient] = useState(false);
    const [redirectToClaim, setRedirectToClaim] = useState(false);

    const [patientAdvSearchData, setPatientAdvSearchData] = useState([]);
    // eslint-disable-next-line no-undef
    var fileDownload = require('js-file-download');

    // for async mutiselect
    const [clearSelectedPatient, setClearSelectedPatient] = useState(0);
    const [clearAMMSelection, setClearAMMSelection] = useState(0);

    // table column sorting
    PaperClaimsTableData.tableHeadings[1].initialOrdering = sorting.initialOrderingClaimId;
    PaperClaimsTableData.tableHeadings[1].orderType = claim_order_type;
    PaperClaimsTableData.tableHeadings[3].initialOrdering = sorting.initialOrderingPatientName;
    PaperClaimsTableData.tableHeadings[3].orderType = orderType;
    PaperClaimsTableData.tableHeadings[2].initialOrdering = sorting.initialOrderingDos;
    PaperClaimsTableData.tableHeadings[2].orderType = orderType;

    function onResetFilter() {
        setPatientPK("")
        setClearAMMSelection((clearAMMSelection + 1));
        setClearSelectedPatient((clearSelectedPatient + 1));
        document.querySelector('button[aria-label="Clear"]')?.click();
        dispatch({ type: "paperclaim/resetFiltersAndData", payload: { status: !reseted } })
    }

    const onHandleCurrency = (e) => {
        if (e.target) {
            let name = e.target.name;
            let value = e.target.value.trim().replace(/[^0-9.]/g, "");
    
            dispatch({ type: "paperclaim/updateFilterData", payload: { name, value } });
    
            const claimAmountTotals = name === "claimAmountTotals" ? value : searchQueryInput?.claimAmountTotals || "";
            const claimAmountTotalsBetween = name === "claimAmountTotalsBetween" ? value : searchQueryInput?.claimAmountTotalsBetween || "";
    
            if (claimAmountTotalsBetween.length > 0 && Number(claimAmountTotalsBetween) < Number(claimAmountTotals)) {
                dispatch(setError({ name: "claimAmountTotalsBetween", error: i18n.t("commons.amountError") }));
            } else {
                dispatch(setError({ name: "claimAmountTotalsBetween", error: "" }));
            }
        }
    };

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        dispatch({ type: "paperclaim/setActivePage", payload: { active: previousPage } });

        if (startIndex !== 0) {
            let start=startIndex - PAGING_END_INDEX;
            let end = endIndex - PAGING_END_INDEX;
            dispatch({ type: "paperclaim/onPagePrevious", payload: { startIndex: start, endIndex: end } });
        }
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        dispatch({ type: "paperclaim/setActivePage", payload: { active: page } });
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            dispatch({ type: "paperclaim/setActivePage", payload: { active: nextPage } });
            dispatch({ type: "paperclaim/onPageNext", payload: { startIndex: startIndex, endIndex: endIndex } });

        } else {
            dispatch({ type: "paperclaim/setActivePage", payload: { active: nextPage } });
            let start = startIndex + PAGING_END_INDEX;
            let end = endIndex + PAGING_END_INDEX;
            dispatch({ type: "paperclaim/onPageNext", payload: { startIndex: start, endIndex: end } });

        }
    }

    function onHandleDateOfServiceFrom(selected) {
        try {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "dateOfServiceFrom", value: selected } })
        } catch {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "dateOfServiceFrom", value: "" } })
        }
    }

    function onHandleDateOfServiceTo(selected) {
        try {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "dateOfServiceTo", value: selected } })
        } catch {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "dateOfServiceTo", value: "" } })
        }
    }

    function onHandleDateOfClaimEntered(selected) {
        try {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "claimEnteredFrom", value: selected } })
        } catch {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "claimEnteredFrom", value: "" } })
        }
    }

    function onHandleDateOfClaimEnteredTo(selected) {
        try {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "claimEnteredTo", value: selected } })
        } catch {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "claimEnteredTo", value: "" } })
        }
    }

    useEffect(() => {
        if (!isFilterDropDownDataReceived) {
            // Dispatch Redux function to get the drop-down list data if it not there already in the state
            dispatch(getFilterDataSet());
        }
        if (PaperClaimsTableData && paperClaimsData.length == 0) {
            getPaperClaimsData(DEFAULT_PAGING_SIZE, activePage)
        }
    }, []);

    // Display error notification if any error occurred while fetching filter dropdown list data
    useEffect(() => {
        if (isErrorGettingDropdownData === true)
            showNotifyWindow('show', 'error', i18n.t('errorMessages.error_getting_dropdown_data'));
    }, [isErrorGettingDropdownData]);


    useEffect(() => {
        if (loading) setShowLoadingBar(true);
        else setShowLoadingBar(false);
    }, [loading])

    useEffect(async () => {
        let responseData =tableData?.length>0 ? tableData : [];
        if (tableData?.length === 0) {
            PaperClaimsTableData.tableBodyData = TableBodyData;
        }
        else {
            const rowArray = commonTableBody(tableData?.length>0?tableData:[], PaperClaimsTableData.tableBodyData[0])
            if (selectedEntries?.length > 0) {
                if (hasCheckedAll) {
                    setCheckedAllClaim(true)
                }
                else {
                    setCheckedAllClaim(false);
                }

                rowArray.forEach((element, index) => {
                    selectedEntries.forEach(item => {
                        if (element[0].id == item) {
                            if (rowArray[index][0].type == 'checkbox')
                                rowArray[index][0].value = true
                        }
                    })
                })
                PaperClaimsTableData.tableBodyData = rowArray;
            }
            else {
                rowArray.forEach((element, index) => {
                    if (rowArray[index][0].type == 'checkbox')
                        rowArray[index][0].value = false

                })
                PaperClaimsTableData.tableBodyData = rowArray;
                setCheckedAllClaim(false);
            }
        }
        setStorage('paperClaimsTableOpened', true);
        setPaperClaimsData(responseData);
        if (tableData !== undefined) {
            setTotalPage(Math.ceil(count / pageSize));
        }

        if (patientSelectedData.length > 0) {
            setPatientAdvSearchData(patientSelectedData);
            setPatientSelected(patientSelectedData);
            setPatientPK(patient_PK)
        }
        else {
            setPatientAdvSearchData([]);
            setPatientSelected([]);
            setPatientPK('')
        }
        setTableUpdateFlag(tableUpdateFlag + 1);
    }, [tableData]);


    useEffect(()=> {
        if (checkboxResetflag){
            const paperClaimsData = PaperClaimsTableData.tableBodyData;

            const updatedTableData = paperClaimsData?.map(row => {
                if (row[0]?.type === "checkbox") {
                    row[0].value = false;
                }
                return row;
            });

            PaperClaimsTableData.tableBodyData = updatedTableData;
            dispatch(setCheckboxResetFlag(false));
        }
    },[checkboxResetflag])

    function onHandleChange(e) {
        if (e.target) {
            let name = e.target.name;
            let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
            let trimmedValue = "";
            trimmedValue = value;
            if (name === "claimAmountTotalsType" && value !== "Between"){
                dispatch({ type: "paperclaim/updateFilterData", payload: { name: "claimAmountTotals", value: "" } });
                dispatch({ type: "paperclaim/updateFilterData", payload: { name: "claimAmountTotalsBetween", value: "" } });
                dispatch(setError({ name: "claimAmountTotalsBetween", error: "" }));
            }
            if (name === "cpt") dispatch({ type: "paperclaim/updateFilterData", payload: { name: "cpt", value: trimmedValue } });
            else if (name === "revenueCodes") dispatch({ type: "paperclaim/updateFilterData", payload: { name: "revenueCodes", value: trimmedValue } });
            else if (name === "icd") dispatch({ type: "paperclaim/updateFilterData", payload: { name: "icd", value: trimmedValue } });
            else if (name === "claimAmountTotalsType") dispatch({ type: "paperclaim/updateFilterData", payload: { name: "claimAmountTotalsType", value: trimmedValue } });
            else if (name === "claimPrintType") dispatch({ type: "paperclaim/updateFilterData", payload: { name: "claimPrintType", value: trimmedValue } });
            else if (name === "claimAmountTotals") dispatch({ type: "paperclaim/updateFilterData", payload: { name: "claimAmountTotals", value: trimmedValue } });
            else if (name === "claimAmountTotalsBetween") dispatch({ type: "paperclaim/updateFilterData", payload: { name: "claimAmountTotalsBetween", value: trimmedValue } });
        } else {
            if (e[0]) {
                setPatientPK(e[0].id);
            } else if (!e[0]) {
                setPatientPK('');
            }
            setPatientSelected(e);
        }
    }

    function removeParameter(queryString,tag) {
        // Find the start of the 'column' parameter
        let columnStart = queryString.indexOf(tag);

        // If 'column' parameter is not found, return the original query string
        if (columnStart === -1) {
            return queryString;
        }

        // Find the end of the 'column' parameter
        let columnEnd = queryString.indexOf('&', columnStart + 1);

        // If there are no more parameters after 'column', remove it till the end of the string
        if (columnEnd === -1) {
            return queryString.substring(0, columnStart);
        }

        // Otherwise, remove the 'column' parameter including the following '&'
        return queryString.substring(0, columnStart) + queryString.substring(columnEnd);
    }

    function getPaperClaimsData(pageSize, page = 1) {
        let query;
        if (!searchButtonClicked) {
            if (previousQuery) {
                query = previousQuery;
                // table column sort
                if (!sorting.initialOrderingPatientName || !sorting.initialOrderingClaimId || !sorting.initialOrderingDos) {
                    let fieldName = ''
                    if (!sorting.initialOrderingPatientName) {
                        fieldName = 'patient__full_name'
                    } else if (!sorting.initialOrderingClaimId) {
                        fieldName = 'custom_claim_id'
                    } else if (!sorting.initialOrderingDos) {
                        fieldName = 'service_from_date'
                    }
                    if (fieldName) {
                        query = removeParameter(query,'&column=');
                        if (fieldName == 'custom_claim_id') {
                            query += '&column=' + claim_order_type + fieldName;
                        }
                        else {
                            query += '&column=' + orderType + fieldName;
                        }
                    }
                }

                if (page) {
                    query = removeParameter(query, '&page=');
                    query+='&page='+page
                }
            }
            else {
                query = '?list=true&page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK
            }
        }
        else {
             query = '?list=true&page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK + '&patient_pk=' + patientPK +
                '&dos_from=' + formatDateUS(searchQueryInput.dateOfServiceFrom) + '&dos_to=' + formatDateUS(searchQueryInput.dateOfServiceTo) + '&claim_entered_from=' + formatDateUS(searchQueryInput.claimEnteredFrom) + '&claim_entered_to=' + formatDateUS(searchQueryInput.claimEnteredTo) +
                '&billing_provider_pk=' + searchQueryInput.provider + '&claim_status_pk=' + searchQueryInput.claimStatus + '&primary_payer_pk=' + searchQueryInput.primaryPayer + '&secondary_payer_pk=' + searchQueryInput.secondaryPayer +
                '&payer_type=' + searchQueryInput.payerType + '&cpt_hcps=' + searchQueryInput.selectedCptIDs + '&icd=' + searchQueryInput.icd + '&claim_amount_condition=' + searchQueryInput.claimAmountTotalsType
                + '&claim_print_type=' + searchQueryInput.claimPrintType + '&claim_type=' + searchQueryInput.claimType;
            if (searchQueryInput.claimType == 2) {
                query += '&revenur_codes=' + searchQueryInput.revenueCodes;
            }
            if (searchQueryInput.claimAmountTotalsType === "Between") { query += '&claim_amount_value=' + searchQueryInput.claimAmountTotals + '||' + searchQueryInput.claimAmountTotalsBetween } else { query += '&claim_amount_value=' + searchQueryInput.claimAmountTotals }
            if (searchQueryInput.currentPayer)
                query += '&current_payer=' + searchQueryInput.currentPayer;
            if (searchQueryInput.selectServiceLocation) {
                query += '&service_location=' + searchQueryInput.serviceLocation;
                query += '&service_location_type=' + searchQueryInput.serviceLocationType;
            }

            // table column sort
            if (!sorting.initialOrderingPatientName || !sorting.initialOrderingClaimId || !sorting.initialOrderingDos) {
                let fieldName = ''
                if (!sorting.initialOrderingPatientName) {
                    fieldName = 'patient__full_name'
                } else if (!sorting.initialOrderingClaimId) {
                    fieldName = 'custom_claim_id'
                } else if (!sorting.initialOrderingDos) {
                    fieldName = 'service_from_date'
                }
                if (fieldName) {
                    if (fieldName == 'custom_claim_id') {
                        query += '&column=' + claim_order_type + fieldName;
                    }
                    else {
                        query += '&column=' + orderType + fieldName;
                    }
                }
            }
        }
        dispatch({ type: "paperclaim/updateSearchButtonClicked", payload: { clicked: false } });
        dispatch({ type: "paperclaim/updatePreviousQuery", payload: { query: query } });
        dispatch(getPaperclaimData(query))
    }

    function sortingFunction(e, name) {
        name = name.replace(" ", "_").toLowerCase();
        if (name == 'patient_name') {
            dispatch({ type: "paperclaim/updateSorting", payload: { name: "patient_name" } })
        } else if (name == 'claim_id') {
            dispatch({ type: "paperclaim/updateSorting", payload: { name: "claim_id" } })
        } else if (name == 'dos') {
            dispatch({ type: "paperclaim/updateSorting", payload: { name: "dos" } })
        }
        if (name == 'claim_id') {
            if (claim_order_type) dispatch({ type: "paperclaim/updateClaimOrderType", payload: { claim_order_type: "" } });
            else dispatch({ type: "paperclaim/updateClaimOrderType", payload: { claim_order_type: "-" } });
            return
        }
        if (!orderType) dispatch({ type: "paperclaim/updateOrderType", payload: { order_type: "-" } });
        else dispatch({ type: "paperclaim/updateOrderType", payload: { order_type: "" } });
    }

    useEffect(() => {
        getPaperClaimsData(DEFAULT_PAGING_SIZE, activePage);
    }, [orderType, searched, reseted, claim_order_type,activePage]);

    useEffect(() => {
        if (patientSelected.length > 0) {
            dispatch({
                type: "paperclaim/patientAdvanceSearchData", payload: {
                    patient_selected: patientSelected,
                    patient_advance_data: patientAdvSearchData,
                    patient_pk: patientPK
                }
            })
        }
        else {
            dispatch({
                type: "paperclaim/patientAdvanceSearchData", payload: {
                    patient_selected: [],
                    patient_advance_data: [],
                    patient_pk: ""
                }
            })
        }
    }, [patientSelected])

    //Reset sorting data
    function resetSort() {
        dispatch({ type: "paperclaim/resetSorting" });
    }

    function onSearchPaperClaims() {
        dispatch(selectEnteries([]));
        setCheckedAllClaim(false);
        dispatch(hasSelectedAll(false))
        dispatch({ type: "paperclaim/statusUpdateSearched", payload: { searched: !searched } });
        dispatch({ type:"paperclaim/updateSearchButtonClicked",payload:{clicked:true}})
        resetSort();
    }

    function getPaperClaimForm(e, claimPK, withHcfa) {
        let query = 'claim_pks=' + claimPK + '&with_hcfa=' + withHcfa;
        const result = searchClaimService.GetPaperClaimFormPDF(query);
        result.then(response => {
            response.headers["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";
            const fileURL = URL.createObjectURL(response.data);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL + '#filename=' + response.headers['content-disposition'];
        });
    }

    async function getBulkPaperClaimForm(e, withHcfa) {
        try {
            if (selectedEntries.length <= 0) {
                showNotifyWindow("show", "error", "Please select a claim");
                return;
            }
            if (selectedEntries.length > 50) {
                showNotifyWindow("show", "error", "Oops! You can only select and print a maximum of 50 items at once.");
                return;
            }

            let data = { 
                'claim_pks': selectedEntries,
                'with_hcfa': withHcfa, 
                'practice_id': Number(practicePK),
                'current_practice_id':Number(practicePK)
            };
            setShowLoadingBar(true);
    
            const response = await searchClaimService.GenerateHCFA(data);

            if (response.status === 200) {

                response.headers["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";
                const blob = new Blob([response.data], { type: "application/pdf" });
                const fileURL = URL.createObjectURL(blob);
                const pdfWindow = window.open("", "_blank");
                if (!pdfWindow) {
                    throw new Error("Pop-up blocker detected");
                }
                pdfWindow.location.href = fileURL + '#filename=' + response.headers['content-disposition'];
                
                dispatch(setCheckboxResetFlag(true));
                if (hasCheckedAll) {
                    setCheckedAllClaim(false);
                }
                getPaperClaimsData(DEFAULT_PAGING_SIZE, activePage);
            } else if (response.data.error_message == "max_allowed_entries_exceeds") {
                showNotifyWindow("show", "error", "Maximum allowed entries exceeded.");
            }
             else if (response.status === 400) {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
            } 
            else {
                showNotifyWindow("show", "error", i18n.t('commons.contactAdminError'));
            }
        } catch (error) {
            if (error.message.includes("Pop-up blocker detected")) {
                showNotifyWindow("show", "error", "Looks like pop-up screen is blocked.");
            }
            console.error("Error while processing paper claim form:", error);
        } finally {
            setShowLoadingBar(false);
        }
    }

    const handleAllCheckedOnChange = (e) => {
        let currentSelectedIds = [];
        if (e.target.checked) {
            PaperClaimsTableData.tableBodyData.forEach(rowItem => {
                let index = rowItem.findIndex(colItem => colItem.type == 'checkbox');
                rowItem[index].value = true;
            });
            setCheckedAllClaim(true);
            dispatch(hasSelectedAll(true))
            paperClaimsData.forEach((item) => {
                currentSelectedIds.push(item.id);
            })
            dispatch(selectEnteries([...new Set([...selectedEntries, ...currentSelectedIds])]));
        } else {
            PaperClaimsTableData.tableBodyData.forEach(rowItem => {
                let index = rowItem.findIndex(colItem => colItem.type == 'checkbox');
                rowItem[index].value = false;
            });
            paperClaimsData.forEach((item) => {
                let index = currentSelectedIds.indexOf(item.id);
                currentSelectedIds.splice(index, 1);
            })
            dispatch(selectEnteries(selectedEntries?.filter((item) => !paperClaimsData?.map(item => item.id)?.includes(item))));
            setCheckedAllClaim(false);
            dispatch(hasSelectedAll(false))
        }
    }

    const handleCheckedOnChange = (e, id) => {

        PaperClaimsTableData.tableBodyData.forEach(rowItem => {
            if (rowItem.find(colItem => colItem.id == id)) {
                let rowIndex = rowItem.findIndex(cItem => cItem.type == 'checkbox');
                rowItem[rowIndex].value = e.target.checked;
            }
        });

        let currentSelectedIds = [];
        let pageAllItemSelected = true;
        selectedEntries.forEach((item) => {
            currentSelectedIds.push(item);
        })
        dispatch(selectEnteries([]));
        let index = currentSelectedIds.indexOf(id);
        if (e.target.checked) {
            if (index === -1) {
                currentSelectedIds.push(id);
            }

        }
        else {
            if (index !== -1) {
                currentSelectedIds.splice(index, 1);
            }
        }
        dispatch(selectEnteries(currentSelectedIds));
        paperClaimsData.forEach((item) => {
            let checkedItem = currentSelectedIds.indexOf(item.id);
            if (checkedItem === -1) {
                pageAllItemSelected = false;
            }
        })
        if (pageAllItemSelected) {
            setCheckedAllClaim(true);
            dispatch(hasSelectedAll(true))
        } else {
            setCheckedAllClaim(false);
            dispatch(hasSelectedAll(false))
        }
    }
    // function remShowExport() {
    //     document.getElementById("export-dropdown1").classList.remove('show');
    //     document.getElementById("export-menu1").classList.remove('show');
    //     document.getElementById("export-dropdown1").classList.remove('active');
    //     document.getElementById("export-menu1").classList.remove('active');
    // }

    function onShowExport() {
        document.getElementById("export-dropdown1").classList.toggle('show');
        document.getElementById("export-menu1").classList.toggle('show');
    }

    function onClaimIDClick(pk, name, claimId) {
        let openedPKs = false;
        const result = searchClaimService.GetSelectedTabs('claims', getStorage("practice"));
        result.then(response => {
            openedPKs = response.data && response.data.opened_tab_pks ? response.data.opened_tab_pks : [];
            let allowedPk = ['search', 'last10'];
            allowedPk.forEach((item) => {
                let index = openedPKs.indexOf(item);
                if (index !== -1) {
                    openedPKs.splice(index, 1);
                }
            })

            if (openedPKs && Array.isArray(openedPKs) && openedPKs.length >= MAX_OPEN_TABS_CLAIMS && !openedPKs.includes(pk)) {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.max_claim_tabs'));
            }
            else {
                let item = { pk: pk, claim_id: claimId, type: 'claims', action: 'add', practice_pk: getStorage("practice") }
                addRemoveFromSelectedTab(item);
            }
        });
    }

    function onPatientNameClick(patientID) {
        const result = searchClaimService.GetSelectedTabs('patients', getStorage("practice"));
        result.then(response => {
            let openedPKs = response.data && response.data.opened_tab_pks ? response.data.opened_tab_pks.filter(item => parseInt(item, 10) > 0) : [];
            if (openedPKs && Array.isArray(openedPKs) && openedPKs.length >= MAX_OPEN_TABS_PATIENTS && !openedPKs.includes(patientID)) {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.max_patient_tabs'));
            }
            else {
                let item = { pk: patientID, type: 'patients', action: 'add', practice_pk: getStorage("practice") }
                addRemoveFromSelectedTab(item);
            }
        });
    }

    function addRemoveFromSelectedTab(item) {
        const result = searchClaimService.AddRemoveSelectedTab(item);
        result.then(response => {
            if (response.data.code === 404) {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.not_found'))
            } else {
                if (item && item.type == 'patients') {
                    setRedirectToPatient(true);
                } else if (item && item.type == 'claims') {
                    setRedirectToClaim(true);
                }
            }
        });
    }
    if (redirectToPatient) {
        return <Redirect to={{ pathname: ROUTE_PATIENTS_LIST }} />;

    }

    if (redirectToClaim) {
        return <Redirect to={{ pathname: ROUTE_SEARCH_CLAIMS }} />;

    }

    function handleClaimType(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'claimType') {
            setClaimType(value)
        }
    }

    function MaterialMultiSelectHandle(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "claimStatus") {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "claimStatus", value: value } });
        } else if (name === 'provider') {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "provider", value: value } });
        } else if (name === 'serviceLocation') {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "selectServiceLocation", value: value } });
            let service_locations = [];
            let service_location_types = [];
            if (value.length > 0) {
                value.forEach((item) => {
                    service_locations.push(item.split("||")[0]);
                    service_location_types.push(item.split("||")[1]);

                });
            }
            dispatch({ type: "paperclaim/updateServiceLocation", payload: { service_location: service_locations, service_location_type: service_location_types } });
        } else if (name === 'currentPayer') {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "currentPayer", value: value } });
        } else if (name === 'primaryPayer') {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "primaryPayer", value: value } });
        } else if (name === 'secondaryPayer') {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "secondaryPayer", value: value } });
        }
        else if (name === 'payerType') {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "payerType", value: value } });
        } else if (name === 'revenueCodes') {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "revenueCodes", value: value } });
        } else if (name === 'icd') {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "icd", value: value } });
        }
    }
    const OnExportMenuItemClicked = (e, type) => {

        setShowLoadingBar(true);
        let query = '?list=true&practice_pk=' + practicePK + '&patient_pk=' + patientPK + '&billing_provider_pk=' + searchQueryInput.provider + '&claim_status_pk=' + searchQueryInput.claimStatus +
            '&primary_payer_pk=' + searchQueryInput.primaryPayer + '&secondary_payer_pk=' + searchQueryInput.secondaryPayer + '&payer_type=' + searchQueryInput.payerType + '&cpt_hcps=' + searchQueryInput.cpt + '&icd=' +
            searchQueryInput.icd + '&current_payer=' + searchQueryInput.currentPayer
        if (searchQueryInput.dateOfServiceFrom) query += '&dos_from=' + format(searchQueryInput.dateOfServiceFrom, 'yyyy-MM-dd')
        if (searchQueryInput.dateOfServiceTo) query += '&dos_to=' + format(searchQueryInput.dateOfServiceTo, 'yyyy-MM-dd')
        if (searchQueryInput.claimEnteredFrom) query += '&claim_entered_from=' + format(searchQueryInput.claimEnteredFrom, 'yyyy-MM-dd')
        if (searchQueryInput.claimEnteredTo) query += '&claim_entered_to=' + format(searchQueryInput.claimEnteredTo, 'yyyy-MM-dd')
        query += '&page=0&export=true&export_type=' + type;
        if (searchQueryInput.selectServiceLocation) {
            query += '&service_location=' + searchQueryInput.serviceLocation;
            query += '&service_location_type=' + searchQueryInput.serviceLocationType;
        }
        searchClaimService.GetListPatientPaperClaims(query, type)
            .then((result) => {
                if (result.status === 200 || result.status === 201) {
                    fileDownload(result.data, result.headers['content-disposition']);
                    setShowLoadingBar(false);
                    showNotifyWindow('show', 'success', 'Export Success');
                } else {
                    showNotifyWindow('show', 'error', result.statusText);
                }
            })
            .catch(() => {
                showNotifyWindow('show', 'error', 'Unexpected Error');
                setShowLoadingBar(false);
            });
    };

    function openLink(id, name) {
        // custom_claim_id patient_name  payer_name
        let item = paperClaimsData.find(obj => obj.id == id);
        if (name == 'custom_claim_id') {
            if (checkPermission(permission_key_values_claim.claims_search_claims_view)) {
                onClaimIDClick(item.id, item.patient_name, item.custom_claim_id, item.patient_pk);
            }
            else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            }
        }
        else if (name == 'patient_name') {
            if (checkPermission(permission_key_values_claim.patient_list_sub_module_view)) {
                onPatientNameClick(item.patient_pk)
            }
            else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            }
        }
        else if (name == 'payer_name') {
            if (item.payer_types.type == 'patient') {
                onPatientNameClick(item.patient_pk)
            }
            else if (item.payer_types.type == 'insurance') {
                history.push(ROUTE_INSURANCE_COMPANIES_EDIT, { selectedID: item.payer_types.pk });
            }
        }
    }

    function onClickGridCheckBox(e, clickedFrom, id) {
        if (clickedFrom == 'data') {
            handleCheckedOnChange(e, id);
        }
        else if (clickedFrom == 'header') {
            handleAllCheckedOnChange(e);
        }
    }

    function exportFunction(e, id, hfdcYesNo) {
        if (checkPermission(permission_key_values_claim.can_view_paper_claim_form)) {
            getPaperClaimForm(e, id, hfdcYesNo);
        }
        else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
        }
    }

    function handleMaterialMultiselectSearch(value) {
        try {
            if (value.length > 0)
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "selectedCptIDs", value: value } })
        } catch {
            dispatch({ type: "paperclaim/updateFilterData", payload: { name: "selectedCptIDs", value: "" } }) 
        }
    }

    return (
        <div className="col-md-12">
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="box basic-info-padding margin-right15">
                <div>
                    <Form autoComplete="off" className="searchBox">
                        <div className="row">
                            <div className="col-6">
                                <div className="row">
                                    <PatientDropDownSearch setPatientPK={setPatientPK} patientSelected={patientSelected} setPatientSelected={setPatientSelected} patientAdvSearchData={patientAdvSearchData} clearSelectedPatient={clearSelectedPatient} fullWidth={true} ></PatientDropDownSearch>
                                </div>
                            </div>
                            <div className="col-6 justify-right margin-top25">
                                <RightSidePanel title={i18n.t("commons.advancedSearch")} onclickLabel={i18n.t("commons.advancedSearch")} >
                                    <PatientsAdvancedSearch setPatientPK={setPatientPK} setPatientSelected={setPatientSelected} setPatientAdvSearchData={setPatientAdvSearchData} >
                                    </PatientsAdvancedSearch>
                                </RightSidePanel>
                            </div>
                        </div>
                        <div className="row margin-top20">
                            <div className="col-3">
                                <CalendarInput name="dateOfServiceFrom"
                                    minDate={patientSelected[0]?.dob}
                                    selected={searchQueryInput.dateOfServiceFrom} onValueChange={onHandleDateOfServiceFrom}
                                    label={i18n.t("paperClaims.dateOfServiceFrom")} />
                            </div>
                            <div className="col-3">
                                <CalendarInput name="dateOfServiceTo"
                                    selected={searchQueryInput.dateOfServiceTo} minDate={searchQueryInput.dateOfServiceFrom} onValueChange={onHandleDateOfServiceTo}
                                    label={i18n.t("paperClaims.dateOfServiceTo")} />
                            </div>
                            <div className="col-3">
                                <CalendarInput name="claimEntered"
                                    selected={searchQueryInput.claimEnteredFrom} onValueChange={onHandleDateOfClaimEntered}
                                    label={i18n.t("paperClaims.claimEntered")} />
                            </div>
                            <div className="col-3">
                                <CalendarInput name="claimEnteredTo"
                                    selected={searchQueryInput.claimEnteredTo} minDate={searchQueryInput.claimEnteredFrom} onValueChange={onHandleDateOfClaimEnteredTo}
                                    label={i18n.t("paperClaims.claimEnteredTo")} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <SelectInput name="claimPrintType" data={ClaimPrintType} value={searchQueryInput.claimPrintType} onValueChange={onHandleChange} label={i18n.t("paperClaims.claimPrintType")} />
                            </div>
                            <div className="col-3">
                                <MaterialMultiselect name="claimStatus" value={searchQueryInput.claimStatus} onValueChange={MaterialMultiSelectHandle}
                                    options={dropDownListData.claimStatusList} label={i18n.t("paperClaims.claimStatus")}
                                />
                            </div>

                            <div className="col-3">
                                <SelectInput name="claimType"
                                    data={CLAIM_TYPES}
                                    value={searchQueryInput.claimType}
                                    setValue={setClaimType}
                                    onValueChange={handleClaimType}
                                    label={i18n.t("claimsFilter.claimType")} />
                            </div>
                            <div className="col-3">
                                <MaterialMultiselect name="provider" value={searchQueryInput.provider} onValueChange={MaterialMultiSelectHandle}
                                    options={dropDownListData.providerList} label={i18n.t("paperClaims.provider")}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <MaterialMultiselect name="serviceLocation" value={searchQueryInput.selectServiceLocation} onValueChange={MaterialMultiSelectHandle}
                                    options={dropDownListData.ServiceLocationList} label={i18n.t("paperClaims.facility")}
                                />
                            </div>
                            <div className="col-3">
                                <MaterialMultiselect name="currentPayer" value={searchQueryInput.currentPayer} onValueChange={MaterialMultiSelectHandle}
                                    options={dropDownListData.payersList} label={i18n.t("paperClaims.currentPayer")}
                                />
                            </div>
                            <div className="col-3">
                                <MaterialMultiselect name="primaryPayer" value={searchQueryInput.primaryPayer} onValueChange={MaterialMultiSelectHandle}
                                    options={dropDownListData.payersList} label={i18n.t("paperClaims.primaryPayer")}
                                />
                            </div>
                            <div className="col-3">
                                <MaterialMultiselect name="secondaryPayer" value={searchQueryInput.secondaryPayer} onValueChange={MaterialMultiSelectHandle}
                                    options={dropDownListData.payersList} label={i18n.t("paperClaims.secondaryPayer")}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <MaterialMultiselect name="payerType" value={searchQueryInput.payerType} onValueChange={MaterialMultiSelectHandle}
                                    options={PAYER_TYPE_LIST} label={i18n.t("paperClaims.payerType")}
                                />
                            </div>
                            <div className="col-3">
                                <MaterialMultiSelectSearch
                                    options={dropDownListData.cptCodeList}
                                    label={i18n.t("claimsFilter.cpt")}
                                    name={"cpt"}
                                    valuesArr={searchQueryInput.selectedCptIDs}
                                    onValueChange={handleMaterialMultiselectSearch}
                                />
                            </div>
                            {claimType === 2 ? <div className="col-3">
                                <MaterialMultiselect name="revenueCodes" value={searchQueryInput.revenueCodes} onValueChange={MaterialMultiSelectHandle}
                                    options={dropDownListData.revenueCodeList} label={i18n.t("paperClaims.revenueCodes")}
                                />
                            </div> : ''}
                            <div className="col-3">
                                <MaterialMultiselect name="icd" value={searchQueryInput.icd} onValueChange={MaterialMultiSelectHandle}
                                    options={ICD} label={i18n.t("paperClaims.icd")}
                                />
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-3">
                                <SelectInput name="claimAmountTotalsType" data={claimAmountType} value={searchQueryInput.claimAmountTotalsType}  onValueChange={onHandleChange} label={i18n.t("paperClaims.claimAmountTotals")} />
                            </div>
                            <div className="col-3">
                                <div className="form-group padding-top25">

                                    <CurrencyInputs
                                        type="claimAmountTotals"
                                        name="claimAmountTotals"
                                        value={searchQueryInput.claimAmountTotals}
                                        onValueChange={onHandleCurrency}
                                        placeholder={i18n.t("paperClaims.dollar")}
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group padding-top25">
                                    {searchQueryInput.claimAmountTotalsType === 'Between' &&
                                        <CurrencyInputs
                                            type="claimAmountbetween"
                                            name="claimAmountTotalsBetween"
                                            value={searchQueryInput.claimAmountTotalsBetween}
                                            onValueChange={onHandleCurrency}
                                            placeholder={i18n.t("paperClaims.dollar")}
                                            required={true}
                                            helperText={searchQueryInput.errors?.claimAmountTotalsBetween || ""}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="col-3 justify-right padding-top20">
                                <div className='margin-right10'>
                                    <CommonButton onClick={onResetFilter} label={i18n.t("buttons.reset")} variant="outlined" />
                                </div>
                                <div>
                                    <CommonButton onClick={onSearchPaperClaims} label={i18n.t("paperClaims.load")} variant="contained" />
                                </div>
                            </div>
                        </div>
                    </Form>
                    <div className="row">
                        <div className="col padding-top10 padding-bottom10">
                            {paperClaimsData.length > 0 &&
                                <div className="justify-right">
                                    <div className="margin-left3 mr-4 padding-top8">
                                        <CommonButton label={i18n.t("buttons.sendViaClrngHouse")} variant="contained" />
                                    </div>
                                    <div className="margin-left3 mr-3 padding-top8 dropdown" id="export-dropdown1">
                                        {/* <button className="header-btn border-none bg-white" type="button" data-toggle="dropdown" onBlur={paperClaimsData && paperClaimsData.length > 0 ? remShowExport : ""} onClick={paperClaimsData && paperClaimsData.length > 0 ? onShowExport : ""}>
                                            <i className='fas fa-print navBarIcons' />
                                        </button> */}
                                        <CommonButton icon="print" label="Print HCFA" variant="outlined" onClick={paperClaimsData && paperClaimsData.length > 0 ? onShowExport : ""} onBlur={onShowExport} />
                                        <div className="">
                                            {checkPermission(permission_key_values_claim.can_view_paper_claim_form) &&
                                                <ul className="dropdown-menu dropdown-menu-right download-dropdown-right" style={{ minWidth: "150px !important", top: "2px" }} id="export-menu1">
                                                    <li className="download-dropdown-menu-item">
                                                        <Link to="#" onMouseDown={(e) => getBulkPaperClaimForm(e, true)} className="text-dec-none" >{i18n.t('commons.exportWithHCFA')}</Link>
                                                    </li>
                                                    <li className="download-dropdown-menu-item">
                                                        <Link to="#" onMouseDown={(e) => getBulkPaperClaimForm(e, false)} className="text-dec-none">{i18n.t('commons.exportWithoutHCFA')}</Link>
                                                    </li>
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                    <div className="padding-top8 dropdown margin-left3" id="grid-export-dropdown">
                                        <div className="">
                                            {checkPermission(permission_key_values_claim.can_view_paper_claim_list) &&
                                                <ExportIconButton
                                                    onExportCsv={(e) => OnExportMenuItemClicked(e, 'csv')}
                                                    onExportXls={(e) => OnExportMenuItemClicked(e, 'xls')} />
                                            }
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>

                    {!paperClaimsTableOpened ? "" :
                        <div style={{ overflowX: "auto", width: "100%"}} className="mb-4">

                            <Table tableObject={PaperClaimsTableData} onLinkClick={openLink} onClickGridCheckBox={onClickGridCheckBox} exportFunction={exportFunction} checkedHeaderCheckBox={checkedAllClaim} sortingFunction={sortingFunction} tblRadiusClass={' table-border-radius12 '}
                                tableUpdateFlag={tableUpdateFlag}/>

                        </div>}
                    <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                    <div className="clear-b-scroll-sector">&nbsp;</div>
                </div>
            </div>
        </div >
    )
}

export default PaperClaims

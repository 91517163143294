import { createSlice } from '@reduxjs/toolkit';

const reportStatusManagerSlice = createSlice({
    name: 'report',
    initialState: {
        isReportRunning: false,
        currentRunningReport: null,
        isSuccess: 'not-set',
        isError: false,
        message: 'not-set',
        isSearch:false
    },
    reducers: {
        setReportRunning(state, action) {
            return {
                ...state,
                isReportRunning: action.payload.isRunning,
                currentRunningReport: action.payload.report,
                isSearch:action.payload.search
            };
        },
        setError(state, action) {
            return {
                ...state,
                isError: action.payload.isError,
                isReportRunning: false,
                currentRunningReport: action.payload.currentRunningReport,
                isSuccess: 'not-set',
            };
        },
        setMessage(state, action) {
            return {
                ...state,
                message: action.payload
            };
        },
        setReportDataReceived(state) {
            return {
                ...state,
                isReportRunning: false,
                currentRunningReport: null,
                isSuccess: true
            };
        },
        resetError(state) {
            return {
                ...state,
                isError: false
            };
        },
    },
});

export const {
    setReportRunning,
    setError,
    setMessage,
    setReportDataReceived,
    resetError
} = reportStatusManagerSlice.actions;

export default reportStatusManagerSlice.reducer;

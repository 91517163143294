import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// Common components
import AutoCompleteSearch from '../../../commons/AutoCompleteSearch';
import SelectInput from '../../../commons/input/SelectInput';
import CustomizedDialogs from '../../../modalWindowComponent/CustomizedDialogs';
import TextInput from "../../../commons/input/input";
import CurrencyInputs from '../../../commons/input/CurrencyInput';

// Services
import claimService from "../../../ClaimModule/services/service";
import service from "../service";
import { getStorage } from '../../../../utilities/browserStorage';
import i18n from '../../../../utilities/i18n';

// External components
import { Form } from 'react-bootstrap';
import { Divider } from '@mui/material';

// Styles
import '../style.css';

// Constants
import { DEFAULT_AND_CUSTOM_OPTIONS } from '../../../../utilities/staticConfigs';
import { DRUG_UNITS, MEASUREMENT_UNITS, YesOrNo } from '../../../../utilities/dictionaryConstants';
import {
    handleDrugCodeValidation,
    ProcedureFormState,
    debounce,
    preparePanelProcedurePayload,
    DEBOUNCE_LIMIT,
    DEFAULT_OPTION_ID,
    CUSTOM_OPTION_ID,
    DEFAULT_NO_ID
} from '../panelUtils';


/**
 * Panel Procedure Insert Update Modal
 * @param {Object} props - The component props
 * @param {boolean} props.showModal - Whether the modal is shown
 * @param {string} props.panelProcedureId - The ID of the panel procedure
 * @param {function} props.onClose - The function to call when the modal is closed
 * @param {function} props.onSave - The function to call when the form is submitted
 * @param {function} props.setShowLoadingBar - The function to call when the loading bar is shown
 * @param {function} props.showNotifyWindow - The function to call when the notify window is shown
 * @param {string} props.panelId - The ID of the panel
 */
function PanelProcedureInsertUpdateModal({
    showModal,
    panelProcedureId,
    onClose,
    onSaveSuccess,
    setShowLoadingBar,
    panelId,
    showNotifyWindow
}) {
    if (!panelId) {
        return null;
    }

    // LOCAL STATE
    const [procedureFormState, setProcedureFormState] = useState(new ProcedureFormState());
    const [cptList, setCptList] = useState([]);
    const [posCodeList, setPosCodeList] = useState([]);
    const [tosCodeList, setTosCodeList] = useState([]);
    const [isLoading, setIsLoading] = useState({
        cpt: false,
        pos: false,
        tos: false,
        m1: false,
        m2: false,
        m3: false,
        m4: false,
        form: false
    });
    const [modifierLists, setModifierLists] = useState({
        m1: [],
        m2: [],
        m3: [],
        m4: []
    });


    /**
     * Effect to initialize form state when panelProcedureId changes or on mount
     */
    useEffect(() => {
        if (!showModal) return; // Only initialize when modal is visible

        const controller = new AbortController();
        initializeFormState(panelProcedureId, { signal: controller.signal });

        // Cleanup: Cancel ongoing requests and reset loading state
        return () => {
            controller.abort();
            setIsLoading(prev => ({ ...prev, form: false }));
        };
    }, [panelProcedureId, showModal]);


    const initializeFormState = async (id) => {
        setIsLoading(prev => ({ ...prev, form: true }));
        setShowLoadingBar(true);

        try {
            if (!id) {
                // Create mode: Reset to default state
                setProcedureFormState(new ProcedureFormState());
            } else {
        // Update mode: Fetch existing data
                const newFormState = new ProcedureFormState();
                const updatedFormState = await newFormState.getSavedPanelProcedure(id);

                if (updatedFormState) {
                    setProcedureFormState(updatedFormState);
                } else {
                    throw new Error("Failed to fetch panel procedure data");
                }
            }
        } catch (error) {
            console.error('Error initializing form state:', error);
            showNotifyWindow("show", "error", "Failed to initialize form data");
            setProcedureFormState(new ProcedureFormState()); // Fallback to default state
        } finally {
            setIsLoading(prev => ({ ...prev, form: false }));
            setShowLoadingBar(false);
        }
    };


    /**
     * Search CPT codes with debouncing
     * @param {string} searchValue - The search string
     */
    const searchCPTCodes = useCallback(
        debounce(async (searchValue) => {
            if (!searchValue) return;

            try {
                setIsLoading(prev => ({ ...prev, cpt: true }));
                const practicePK = getStorage('practice');
                const result = await claimService.GetCPTCodeList(0, 0, practicePK, "dropdown", searchValue);
                if (result.data) {
                    setCptList(result.data);
                }
            } catch (error) {
                console.error('Error fetching CPT codes:', error);
                setCptList([]);
            } finally {
                setIsLoading(prev => ({ ...prev, cpt: false }));
            }
        }, DEBOUNCE_LIMIT),
        []
    );


    /**
     * Search POS codes with debouncing
     * @param {string} searchValue - The search string
     */
    const searchPosCodes = useCallback(
        debounce(async (searchValue) => {
            if (!searchValue) return;

            try {
                setIsLoading(prev => ({ ...prev, pos: true }));
                const result = await claimService.SearchPosCodes(searchValue);

                if (result.data?.results) {
                    const formattedResults = result.data.results.map(item => ({
                        id: item.id,
                        name: item.drop_down_name,
                        label: item.drop_down_name
                    }));
                    setPosCodeList(formattedResults);
                }
            } catch (error) {
                console.error('Error fetching POS codes:', error);
                setPosCodeList([]);
            } finally {
                setIsLoading(prev => ({ ...prev, pos: false }));
            }
        }, DEBOUNCE_LIMIT),
        []
    );


    /**
     * Search TOS codes with debouncing
     * @param {string} searchValue - The search string
     */
    const searchTosCodes = useCallback(
        debounce(async (searchValue) => {
            if (!searchValue) return;

            try {
                setIsLoading(prev => ({ ...prev, tos: true }));
                const result = await claimService.SearchTosCodes(searchValue);

                if (result.data?.results) {
                    const formattedResults = result.data.results.map(item => ({
                        id: item.id,
                        name: item.drop_down_name,
                        label: item.drop_down_name
                    }));
                    setTosCodeList(formattedResults);
                }
            } catch (error) {
                console.error('Error fetching TOS codes:', error);
                setTosCodeList([]);
            } finally {
                setIsLoading(prev => ({ ...prev, tos: false }));
            }
        }, DEBOUNCE_LIMIT),
        []
    );

    /**
     * Search modifiers with debouncing
     * @param {string} searchValue - The search string
     * @param {string} modifierField - The modifier field
     */
    const searchModifiers = useCallback(
        debounce(async (searchValue, modifierField) => {
            if (!searchValue) return;

            try {
                setIsLoading(prev => ({ ...prev, [modifierField]: true }));
                const result = await claimService.GetModifier(searchValue);

                if (result.data?.results) {
                    const formattedResults = result.data.results.map(item => ({
                        id: item.id,
                        name: item.drop_down_name,
                        label: item.drop_down_name
                    }));
                    setModifierLists(prev => ({
                        ...prev,
                        [modifierField]: formattedResults
                    }));
                }
            } catch (error) {
                console.error(`Error fetching ${modifierField} codes:`, error);
                setModifierLists(prev => ({
                    ...prev,
                    [modifierField]: []
                }));
            } finally {
                setIsLoading(prev => ({ ...prev, [modifierField]: false }));
            }
        }, DEBOUNCE_LIMIT),
        []
    );


    /**
     * Form field value change handler
     * @param {string} field - The field to update
     * @param {*} value - The new value for the field
     */
    const handleValueChange = (field, value) => {
        // Handle numeric fields that should only contain whole numbers
        const wholeNumberFields = ['fee_units', 'drug_unit_value', 'measurement_unit_value', 'anesthesia'];
        if (wholeNumberFields.includes(field) && value) {
            // Ensure value is a string before processing
            const stringValue = String(value);
            // Remove any non-digit characters and leading zeros
            const cleanValue = stringValue.replace(/[^\d]/g, '').replace(/^0+(\d)/, '$1');
            // Limit to 9 digits maximum
            const formattedValue = cleanValue.substring(0, 9);
            // Use empty string if the result is empty
            value = formattedValue || '';
        }

        // Handle CPT/HCPCS special case as it needs to reset the form
        if (field === 'cpt_hcpcs') {
            const newFormState = new ProcedureFormState();
            newFormState.panelProcedureId = procedureFormState.panelProcedureId;
            newFormState.updateWithNewProcedureData(value);
            setProcedureFormState(newFormState);
            return;
        }

        // Handle fee and units special case - auto calculate total charges
        if (field === 'fee' || field === 'fee_units') {

            if (field === "fee" && value) {
                const num = parseFloat(value);
                // Check if number of decimal places exceeds 3
                const decimalPlaces = num.toString().split('.')[1]?.length || 0;
                if (decimalPlaces >= 3) {
                    value = num.toFixed(2);
                }
            }

            setProcedureFormState(prev => {
                // Get current values, using the new value for the changed field
                const fee = field === 'fee' ? parseFloat(value) || 0 : parseFloat(prev.fee) || 0;
                const units = field === 'fee_units' ? parseFloat(value) || 0 : parseFloat(prev.fee_units) || 0;

                // Calculate total charges (rounded to 2 decimal places)
                const totalCharges = Math.round(fee * units * 100) / 100;

                return {
                    ...prev,
                    [field]: value,
                    total_charges: fee > 0 && units > 0 ? totalCharges : ""
                };
            });
            return;
        }

        // Handle array-based fields (POS, TOS, Modifiers)
        const arrayFields = ['pos', 'tos', 'm1', 'm2', 'm3', 'm4'];
        if (arrayFields.includes(field)) {
            setProcedureFormState(prev => ({
                ...prev,
                [field]: value ? {
                    id: value.id,
                    name: value.name || value.code,
                    label: value.label || value.drop_down_name,
                    description: value.description
                } : null
            }));
            return;
        }

        // Handle modifier option special case - reset modifiers when switching to default
        if (field === 'modifier_option') {
            // When switching to default, fetch default values
            if (value === DEFAULT_OPTION_ID && procedureFormState.cpt_hcpcs?.id) {
                const formState = new ProcedureFormState();
                setShowLoadingBar(true);
                // Fetch default values
                formState.fetchProcedureDefaultValues(procedureFormState.cpt_hcpcs.id).then(defaultValues => {
                    if (defaultValues) {
                        setProcedureFormState(prev => ({
                            ...prev,
                            modifier_option: value,
                            ...(value === DEFAULT_OPTION_ID && {
                                m1: defaultValues.m1,
                                m2: defaultValues.m2,
                                m3: defaultValues.m3,
                                m4: defaultValues.m4
                            })
                        }));
                    } else {
                        setProcedureFormState(prev => ({
                            ...prev,
                            modifier_option: value,
                            m1: null,
                            m2: null,
                            m3: null,
                            m4: null
                        }));
                    }
                }).finally(() => {
                    setShowLoadingBar(false);
                });
                return;
            }
            // When switching to custom, reset modifiers
            setProcedureFormState(prev => ({
                ...prev,
                modifier_option: value,
                ...(value === CUSTOM_OPTION_ID && {
                    m1: null,
                    m2: null,
                    m3: null,
                    m4: null
                })
            }));
            return;
        }

        if (field === 'fee_option') {
            // When switching to default, fetch the default fee and units values of the cpt code
            if (value === DEFAULT_OPTION_ID && procedureFormState.cpt_hcpcs?.id) {
                const formState = new ProcedureFormState();
                setShowLoadingBar(true);
                formState.fetchProcedureDefaultValues(procedureFormState.cpt_hcpcs.id).then(defaultValues => {
                    if (defaultValues) {
                        setProcedureFormState(prev => ({
                            ...prev,
                            fee_option: value,
                            fee: defaultValues.fee,
                            fee_units: defaultValues.fee_units,
                            total_charges: defaultValues.total_charges
                        }));
                    } else {
                        setProcedureFormState(prev => ({
                            ...prev,
                            fee_option: value,
                            fee: null,
                            fee_units: null,
                            total_charges: null
                        }));
                    }
                }).finally(() => {
                    setShowLoadingBar(false);
                });
                return;
            }

            setProcedureFormState(prev => ({
                ...prev,
                fee_option: value,
                ...(value === CUSTOM_OPTION_ID && {
                    fee: null,
                    fee_units: null,
                    total_charges: null
                })
            }));
            return;
        }

        if (field === 'drug_code') {
            if (!value) {
                setProcedureFormState(prev => ({
                    ...prev,
                    drug_code: "",
                    drug_code_valid: true,
                    drug_unit_value: "",
                    drug_unit_type: ""
                }));
                return;
            }
            const { formattedValue, isValid } = handleDrugCodeValidation(value);
            setProcedureFormState(prev => ({
                ...prev,
                drug_code: formattedValue,
                drug_code_valid: isValid
            }));
            return;
        }

        // Handle all other fields
        setProcedureFormState(prev => ({
            ...prev,
            [field]: value
        }));
    };


    /**
     * React form submit event handler for inserting or updating a panel procedure
     * @param {*} e react form submit event
     * @async
     * @returns {Promise<void>}
     */
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            setShowLoadingBar(true);

            const { payload, isValid, errors } = preparePanelProcedurePayload(procedureFormState, panelId);

            if (!isValid) {
                showNotifyWindow("show", "error", errors.join("\n"));
                return;
            }

            // Call appropriate API based on whether it's an insert or update
            let result;
            if (panelProcedureId) {
                payload.panel_procedure_id = procedureFormState.panelProcedureId;
                // Update existing procedure
                result = await service.UpdatePanelProcedure(panelProcedureId, payload);
            } else {
                // Insert new procedure
                result = await service.InsertProcedureToPanel(payload);
            }

            handleApiStatus(result);
        } catch (error) {
            console.error('Error saving procedure:', error);
            showNotifyWindow("show", "error", "Failed to save procedure");
        } finally {
            setShowLoadingBar(false);
        }
    };


    /**
     * Handle the API status and show the appropriate notification
     * @param {Object} result - The result object from the API call
     */
    const handleApiStatus = (response) => {
        const notifications = {
            "charge_panel_procedure_already_exists": {
                status: "error",
                message: "Procedure already exists"
            },
            "fee_should_not_be_scientific_notation_max_decimal_point_is_32": {
                status: "error",
                message: "Fee should not be in scientific notation and maximum decimal points allowed is 32"
            },
            "fee_must_not_have_more_than_two_decimal_places": {
                status: "error",
                message: "Fee must not have more than two decimal places"
            },
            "fee_must_be_a_valid_decimal_number": {
                status: "error",
                message: "Fee must be a valid decimal number"
            },
            "fee_unit_should_not_have_decimal_places": {
                status: "error",
                message: "Fee unit should Typically whole numbers are expected not have decimal places"
            },
            "fee_unit_must_be_a_valid_integer": {
                status: "error",
                message: "Fee unit must be a valid number"
            }
        };

        const apiMessage = response?.data?.message;
        const notification = notifications[apiMessage];

        if (notification) {
            // Show notification based on the predefined messages
            showNotifyWindow("show", notification.status, notification.message);
        } else if ([200, 201].includes(response.status)) {
            // Success case: Procedure saved successfully
            showNotifyWindow("show", "success", "Procedure saved successfully");
            onSaveSuccess(response.status, response.data);

            // Close after 1 second
            setTimeout(onClose, 1000);
        } else {
            // Handle unexpected cases or errors
            const errorMessage = apiMessage || "Failed to save procedure";
            showNotifyWindow("show", "error", errorMessage);
            throw new Error(errorMessage);
        }
    };



    return (
        <CustomizedDialogs
            showModal={showModal}
            type="save"
            header={panelProcedureId ? "Update Procedure" : "Insert Procedure"}
            setShowModalWindow={onClose}
            onHide={onClose}
        >
            <Form
                id="form_dataEntry"
                autoComplete="off"
                onSubmit={handleFormSubmit}
                encType="multipart/form-data"
            >
                <Form.Group>
                    <div className="panel-procedure-insert-update-modal">
                        <label className="mt-3" style={{ fontSize: '1.1rem', fontWeight: 500 }}>Codes</label>
                        <Divider style={{ color: "#000", marginBottom: 15 }} />

                        <div className="panel-procedure-insert-update-section">
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <AutoCompleteSearch
                                        id="panel-procedure-cpt-hcpcs"
                                        options={cptList}
                                        multiple={false}
                                        onInputChange={(e) => searchCPTCodes(e?.target?.value || "")}
                                        loading={isLoading.cpt}
                                        onChange={(e, value) => handleValueChange('cpt_hcpcs', value)}
                                        value={procedureFormState.cpt_hcpcs || null}
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.cpt")}
                                        placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.cpt")}
                                        error={!procedureFormState.cpt_hcpcs}
                                        getOptionLabel={(option) => {
                                            const description = option.description || "";
                                            const shortDescription = description.length > 40
                                                ? `${description.substring(0, 40)}...`
                                                : description;
                                            return `${option.name} - ${shortDescription}`;
                                        }}
                                        renderOption={(props, option) => (
                                            <li {...props}>
                                                <div>
                                                    <strong>{option.name}</strong>
                                                    <span> - {option.description}</span>
                                                </div>
                                            </li>
                                        )}
                                        disableCloseOnSelect={false}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <AutoCompleteSearch
                                        id="panel-procedure-pos-code"
                                        options={posCodeList}
                                        multiple={false}
                                        onChange={(e, value) => handleValueChange('pos', value)}
                                        loading={isLoading.pos}
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.pos")}
                                        placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.pos")}
                                        error={!procedureFormState.pos}
                                        onInputChange={(e) => searchPosCodes(e?.target?.value || "")}
                                        value={procedureFormState.pos || null}
                                        disableCloseOnSelect={false}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <AutoCompleteSearch
                                        id="panel-procedure-tos-code"
                                        options={tosCodeList}
                                        multiple={false}
                                        onChange={(e, value) => handleValueChange('tos', value)}
                                        loading={isLoading.tos}
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.tos")}
                                        placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.tos")}
                                        onInputChange={(e) => searchTosCodes(e?.target?.value || "")}
                                        value={procedureFormState.tos || null}
                                        disableCloseOnSelect={false}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-4'>
                                    <SelectInput
                                        id="panel-procedure-modifier-option"
                                        label="Modifier Option"
                                        data={DEFAULT_AND_CUSTOM_OPTIONS}
                                        required={true}
                                        selectOptionRemove={true}
                                        value={procedureFormState.modifier_option}
                                        onValueChange={(e) => handleValueChange('modifier_option', e?.target?.value || null)}
                                        error={!procedureFormState.modifier_option}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <AutoCompleteSearch
                                        id="panel-procedure-m1"
                                        options={modifierLists.m1}
                                        multiple={false}
                                        disabled={procedureFormState.modifier_option === 1}
                                        onChange={(e, value) => handleValueChange('m1', value)}
                                        loading={isLoading.m1}
                                        label="Modifier 1"
                                        placeholder="Search modifier"
                                        onInputChange={(e) => searchModifiers(e?.target?.value || "", 'm1')}
                                        value={procedureFormState.m1 || null}
                                        getOptionLabel={(option) => option.label}
                                        disableCloseOnSelect={false}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <AutoCompleteSearch
                                        id="panel-procedure-m2"
                                        options={modifierLists.m2}
                                        multiple={false}
                                        disabled={procedureFormState.modifier_option === 1}
                                        onChange={(e, value) => handleValueChange('m2', value)}
                                        loading={isLoading.m2}
                                        label="Modifier 2"
                                        placeholder="Search modifier"
                                        onInputChange={(e) => searchModifiers(e?.target?.value || "", 'm2')}
                                        value={procedureFormState.m2 || null}
                                        getOptionLabel={(option) => option.label}
                                        disableCloseOnSelect={false}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <AutoCompleteSearch
                                        id="panel-procedure-m3"
                                        options={modifierLists.m3}
                                        multiple={false}
                                        disabled={procedureFormState.modifier_option === 1}
                                        onChange={(e, value) => handleValueChange('m3', value)}
                                        loading={isLoading.m3}
                                        label="Modifier 3"
                                        placeholder="Search modifier"
                                        onInputChange={(e) => searchModifiers(e?.target?.value || "", 'm3')}
                                        value={procedureFormState.m3 || null}
                                        getOptionLabel={(option) => option.label}
                                        disableCloseOnSelect={false}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <AutoCompleteSearch
                                        id="panel-procedure-m4"
                                        options={modifierLists.m4}
                                        multiple={false}
                                        disabled={procedureFormState.modifier_option === 1}
                                        onChange={(e, value) => handleValueChange('m4', value)}
                                        loading={isLoading.m4}
                                        label="Modifier 4"
                                        placeholder="Search modifier"
                                        onInputChange={(e) => searchModifiers(e?.target?.value || "", 'm4')}
                                        value={procedureFormState.m4 || null}
                                        getOptionLabel={(option) => option.label}
                                        disableCloseOnSelect={false}
                                    />
                                </div>
                            </div>
                        </div>

                        <label className="mt-3" style={{ fontSize: '1.1rem', fontWeight: 500 }}>Fee and Charges</label>
                        <Divider style={{ color: "#000", marginBottom: 15 }} />

                        <div className="panel-procedure-insert-update-section">
                            <div className='row mb-3'>
                                <div className='col-md-4'>
                                    <SelectInput
                                        id="panel-procedure-fee-option"
                                        label="Fee Option"
                                        data={DEFAULT_AND_CUSTOM_OPTIONS}
                                        required={true}
                                        value={procedureFormState.fee_option}
                                        selectOptionRemove={true}
                                        onValueChange={(e) => handleValueChange('fee_option', e?.target?.value || null)}
                                        error={!procedureFormState.fee_option}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <TextInput
                                        type="number" name={"fee"}
                                        id={`panel-procedure-fee`}
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.fee")}
                                        disabled={procedureFormState.fee_option === 1 ? "disabled" : ""}
                                        value={procedureFormState.fee ?? ""}
                                        onValueChange={(e) => handleValueChange('fee', e?.target?.value || null)}
                                        defaultClassName={
                                            procedureFormState.fee_option === CUSTOM_OPTION_ID
                                                ? ((!procedureFormState.fee && procedureFormState.fee !== 0) ||
                                                    isNaN(procedureFormState.fee) ||
                                                    Number(procedureFormState.fee) < 0)
                                                    ? "input-error"
                                                    : ""
                                                : ""
                                        }
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <TextInput
                                        type="text" name={"fee_units"}
                                        id={`panel-procedure-fee-units`}
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.units")}
                                        disabled={procedureFormState.fee_option === 1 ? "disabled" : ""}
                                        value={procedureFormState.fee_units ?? ""}
                                        onValueChange={(e) => handleValueChange('fee_units', e?.target?.value || null)}
                                        defaultClassName={
                                            procedureFormState.fee_option === CUSTOM_OPTION_ID
                                                ? ((!procedureFormState.fee_units && procedureFormState.fee_units !== 0) ||
                                                    isNaN(procedureFormState.fee_units) ||
                                                    Number(procedureFormState.fee_units) < 0)
                                                    ? "input-error"
                                                    : ""
                                                : ""
                                        }
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <CurrencyInputs type="text" name={"charges"} isAdornment={false}
                                        id={`panel-procedure-charges`}
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.charges")}
                                        disabled={true}
                                        value={procedureFormState.total_charges || ""}
                                        onValueChange={(e) => handleValueChange('charges', e?.target?.value || null)}
                                    />
                                </div>
                            </div>
                        </div>

                        <label className="mt-3" style={{ fontSize: '1.1rem', fontWeight: 500 }}>Other Information</label>
                        <Divider style={{ color: "#000", marginBottom: 15 }} />

                        <div className="panel-procedure-insert-update-section">
                            <div className='row'>
                                <div className='col-md-2'>
                                    <SelectInput
                                        id="panel-procedure-emg"
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.emg")}
                                        data={YesOrNo}
                                        value={procedureFormState.emg}
                                        onValueChange={(e) => handleValueChange('emg', e?.target?.value || DEFAULT_NO_ID)}
                                        selectOptionRemove={true}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <SelectInput
                                        id="panel-procedure-epsdt"
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.espdt")}
                                        data={YesOrNo}
                                        value={procedureFormState.cpt_epsdt}
                                        onValueChange={(e) => handleValueChange('cpt_epsdt', e?.target?.value || DEFAULT_NO_ID)}
                                        selectOptionRemove={true}
                                    />
                                </div>
                                <div className='col-md-8'>
                                    <TextInput
                                        type="text"
                                        name="proced_description"
                                        id="panel-procedure-description"
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.procedDesc")}
                                        value={procedureFormState.proced_description || ""}
                                        onValueChange={(e) => handleValueChange('proced_description', e?.target?.value || "")}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-8'>
                                    <TextInput
                                        type="text"
                                        name="line_note"
                                        id="panel-procedure-line-note"
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.lineNote")}
                                        value={procedureFormState.line_note || ""}
                                        onValueChange={(e) => handleValueChange('line_note', e?.target?.value || "")}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <TextInput
                                        type="text"
                                        name="anesthesia"
                                        id="panel-procedure-anesthesia"
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.anesthesia")}
                                        value={procedureFormState.anesthesia || ""}
                                        onValueChange={(e) => handleValueChange('anesthesia', e?.target?.value || "")}
                                    />
                                </div>
                            </div>

                            <label className="mt-2 mb-2">Drug Information</label>
                            <div className='row mb-2'>
                                <div className='col-md-4'>
                                    <TextInput
                                        type="text"
                                        name="drug_code"
                                        id="panel-procedure-drug-code"
                                        label="Drug Code"
                                        value={procedureFormState.drug_code || ""}
                                        onValueChange={(e) => {
                                            const { formattedValue, isValid } = handleDrugCodeValidation(e.target.value);
                                            handleValueChange('drug_code', formattedValue);
                                            handleValueChange('drug_code_valid', isValid);
                                        }}
                                        placeholder="XXXXX-XXXX-XX"
                                        helperText={procedureFormState.drug_code ? "Format: XXXXX-XXXX-XX" : ""}
                                        defaultClassName={
                                            ((procedureFormState.drug_code && !procedureFormState.drug_code_valid) ||
                                                (!procedureFormState.drug_code && (procedureFormState.drug_unit_type || procedureFormState.drug_unit_value)))
                                                ? "input-error"
                                                : ""
                                        }
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <SelectInput
                                        id="panel-procedure-drug-unit-type"
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.drugUnits")}
                                        className={(procedureFormState.drug_code && !procedureFormState.drug_unit_type) ? "input-error" : ""}
                                        data={DRUG_UNITS}
                                        value={procedureFormState.drug_unit_type}
                                        onValueChange={(e) => handleValueChange('drug_unit_type', e?.target?.value || null)}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <TextInput
                                        type="text"
                                        name="drug_unit_value"
                                        id="panel-procedure-drug-unit-value"
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.units")}
                                        defaultClassName={(procedureFormState.drug_code && !procedureFormState.drug_unit_value) ? "input-error" : ""}
                                        value={procedureFormState.drug_unit_value || ""}
                                        onValueChange={(e) => handleValueChange('drug_unit_value', e?.target?.value || null)}
                                        error={!procedureFormState.drug_code && !procedureFormState.drug_unit_value}
                                    />
                                </div>
                            </div>

                            <label className="mt-2 mb-2">Measurement Information</label>
                            <div className='row mb-2'>
                                <div className='col-md-4'>
                                    <SelectInput
                                        id="panel-procedure-measurement-unit-type"
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.measurements")}
                                        data={MEASUREMENT_UNITS}
                                        value={procedureFormState.measurement_unit_type}
                                        onValueChange={(e) => handleValueChange('measurement_unit_type', e?.target?.value || null)}
                                        className={(procedureFormState.measurement_unit_value && !procedureFormState.measurement_unit_type) ? "input-error" : ""}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <TextInput
                                        type="text"
                                        name="measurement_unit_value"
                                        id="panel-procedure-measurement-unit-value"
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.units")}
                                        defaultClassName={(procedureFormState.measurement_unit_type && !procedureFormState.measurement_unit_value) ? "input-error" : ""}
                                        value={procedureFormState.measurement_unit_value || ""}
                                        onValueChange={(e) => handleValueChange('measurement_unit_value', e?.target?.value || "")}
                                        error={Boolean(procedureFormState.measurement_unit_type) !== Boolean(procedureFormState.measurement_unit_value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Form.Group>
            </Form>
        </CustomizedDialogs>
    );
}

export default PanelProcedureInsertUpdateModal;


PanelProcedureInsertUpdateModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    panelProcedureId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    setShowLoadingBar: PropTypes.func.isRequired,
    panelId: PropTypes.string.isRequired
};
import React, { useEffect, useState } from 'react'
import CommonButton from '../../commons/Buttons'
import i18n from '../../../utilities/i18n'
import { Popover, Stack, Checkbox, Typography, Divider, IconButton } from '@mui/material';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 75,

        },
    },
};

/**
 * ACCEPETED PARAMS
 * practicesList = Practice list array
 * associateUserSelectedPractices = current selected practices
 * addPracticeDataToBox = handleFunction 
 * setSelectedPracticesAndRoles = {setSelectedPracticesAndRoles} Function
 * selectedPracticesAndRoles = { selectedPracticesAndRoles } Array
 * setDefaultPractice = {setDefaultPractice} Function
 * defaultPractice = {Default practice}
 * selectedPracticesAndRoles = { selectedPracticesAndRoles } Array
 * removePractice = {remove Practice} function
 */

function AssociateUsersPracticeRoleSelector({ resetForm,
    practicesList,
    associateUserSelectedPractices,
    addPracticeDataToBox,
    practicesAndRoles,
    removePractice,
    setSelectedPracticesAndRoles,
    selectedPracticesAndRoles,
    setDefaultPractice,
    defaultPractice
}) {
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [popOverAnchor, setPopOverAnchor] = useState(null);
    const [isPopOverOpen, setIsPopOverOpen] = useState(false);

    useEffect(() => {
        const initialSelectedRoles = practicesAndRoles
            ?.filter(item => associateUserSelectedPractices.some(selected => selected === item?.practice_id))
            ?.map((item, index) => ({
                id: index,
                practiceid: item?.practice_id,
                roleid: item.roles.length > 0 ? item.roles[0].id : null,
            })) || [];
    
        setSelectedRoles(initialSelectedRoles);
    
        if (initialSelectedRoles.length > 0) {
            const existingDefault = initialSelectedRoles.some(item => item.practiceid === defaultPractice);
            if (existingDefault) {
                setDefaultPractice(defaultPractice);
            } else {
                setDefaultPractice(initialSelectedRoles[initialSelectedRoles.length - 1]?.practiceid);
            }
        } 

        if (associateUserSelectedPractices.length == 0) {
                setDefaultPractice("");
            }

    }, [practicesAndRoles, associateUserSelectedPractices]);



    const handleChange = (index, e, practice_id) => {
        const { value } = e.target;

        // Find if the combination of practice_id and id exists in the selectedRoles array
        const existingIndex = selectedRoles.findIndex(
            (item) => item.practiceid === practice_id && item.id === index
        );

        if (existingIndex !== -1) {
            // If the combination already exists, update the roleid
            const updatedItems = [...selectedRoles];
            updatedItems[existingIndex] = {
                ...updatedItems[existingIndex],
                roleid: value,
            };
            setSelectedRoles(updatedItems);
        } else {
            // If the combination doesn't exist, add it to the array
            setSelectedRoles([
                ...selectedRoles,
                { id: index, practiceid: practice_id, roleid: value },
            ]);
        }

        handleRoleSelect(practice_id, value)
    };

    const handleAddPracticeClick = (event) => {
        setPopOverAnchor(event.currentTarget);
        setIsPopOverOpen(true)
    };

    const handlePopOverClose = () => {
        setPopOverAnchor(null);
        setIsPopOverOpen(false);
    };

    const handleClose = (e, pId) => {
        removePractice(pId);
        setSelectedPracticesAndRoles((prevState) => {
            const newState = { ...prevState };
            delete newState[pId]
            return newState;
        });
    }

    const handleSelection = (id, isChecked) => {
        addPracticeDataToBox(isChecked ? associateUserSelectedPractices.filter(item => item !== id) : [...associateUserSelectedPractices, id])
        if (isChecked) {
            setSelectedPracticesAndRoles((prevState) => {
                const newState = { ...prevState };
                delete newState[id]
                return newState;
            });
        } else {
            setSelectedPracticesAndRoles((prevState) => {
                const newState = { ...prevState };
                newState[id] = {};
                return newState;
            });
        }
    }

    function handleRoleSelect(practiceId, roleId) {
        setSelectedPracticesAndRoles((prevState) => {
            const newState = { ...prevState };
            if (Object.prototype.hasOwnProperty.call(newState, practiceId)) {
                newState[practiceId] = { [roleId]: true };
            } else {
                newState[practiceId] = { [roleId]: true };
            }
            return newState;
        });
    }


    return (
        <>
            <div>

                <div className='row'>
                    <div className='col-4'>
                        <h5 style={{ paddingTop: '15px', fontFamily: 'Lato', fontSize: '13px', color: '#616161', paddingLeft: '3px' }}>Practices</h5>
                    </div>
                    <div className='col-4' style={{ display: 'flex', justifyContent: "flex-end" }}>
                        <div style={{ marginRight: '20px' }}>
                            <CommonButton variant="outlined"
                                onClick={resetForm}
                                label={i18n.t('commons.clearAll')} />
                        </div>
                        <div>
                            <CommonButton variant="contained"
                                icon='add'
                                label={i18n.t('commons.addNewPractices')}
                                onClick={handleAddPracticeClick}
                            />
                            <Popover
                                id={1}
                                open={isPopOverOpen}
                                anchorEl={popOverAnchor}
                                onClose={handlePopOverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                {
                                    practicesList.length > 0 ?
                                        practicesList.map((item) => {
                                            const isChecked = associateUserSelectedPractices.some(item1 => item1 === item.id);
                                            return (
                                                <Stack key={item.id}
                                                    sx={{
                                                        boarderRadius: '3px',
                                                        cursor: 'pointer',
                                                        background: isChecked ? 'rgba(158,207,250,0.3)' : '',
                                                        '&:hover': {
                                                            backgroundColor: !isChecked ? '#dbdfe2a8' : ''
                                                        },
                                                    }}
                                                    onClick={() => { handleSelection(item.id, isChecked) }} direction="row" alignItems="center" gap={1} padding="3px">
                                                    <Checkbox checked={isChecked} className='ml-3' style={{ color: isChecked ? '#24A7E0' : 'inherit' }} />
                                                    <Typography variant='subtitle1' className='ml-3'>
                                                        {item.name}
                                                    </Typography>
                                                </Stack>
                                            )
                                        })
                                        : null
                                }
                            </Popover>
                        </div>
                    </div>
                    <div className='col-4'></div>
                </div>

                <div style={{ minHeight: "100px", width: '66%', border: "1px solid #80808063 ", gap: 8, marginTop: '10px', marginLeft: '2px', borderRadius: "4px", padding: "16px" }}>
                    {
                        practicesAndRoles
                            ?.filter(item => associateUserSelectedPractices.some(selected => selected === item?.practice_id))
                            ?.map((item, index) => {
                                return (
                                    <React.Fragment key={item.practice_id}>
                                        <div className='row' >
                                            <div className='col-4 mb-2' >
                                                <Chip className='chip-label-mui' key={item.practice_id} label={item?.practice_name} />
                                            </div>
                                            <div className='col-8' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <FormControl sx={{ m: 1, width: 200 }}>
                                                    <Select
                                                        className='MuiFormControl-root-select12 '
                                                        labelId="demo-multiple-name-label"
                                                        id="demo-multiple-name"
                                                        value={selectedPracticesAndRoles[item.practice_id] ? Object.keys(selectedPracticesAndRoles[item.practice_id])?.find(group => selectedPracticesAndRoles[item.practice_id][group]) : ''}
                                                        onChange={(e) => handleChange(index, e, item?.practice_id)}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {item.roles.map((roleItem) => (
                                                            <MenuItem
                                                                key={roleItem.group}
                                                                value={roleItem.group}
                                                                style={{
                                                                    display: "flex", justifyContent: "space-between", paddingLeft: "8px", paddingTop: "-3px", paddingBottom: "-3px",
                                                                    background: selectedPracticesAndRoles[item.practice_id] && roleItem.group === Object.keys(selectedPracticesAndRoles[item.practice_id]).find(group => selectedPracticesAndRoles[item.practice_id][group]) ? 'rgba(158,207,250,0.3)' : '',
                                                                    '&:hover ': {
                                                                        backgroundColor: selectedPracticesAndRoles[item.practice_id] && roleItem.group === Object.keys(selectedPracticesAndRoles[item.practice_id]).find(group => selectedPracticesAndRoles[item.practice_id][group]) ? '#dbdfe2a8' : ''
                                                                    },
                                                                }}
                                                            >
                                                                {roleItem.role_name}
                                                            </MenuItem>)
                                                        )}
                                                    </Select>
                                                </FormControl>
                                                <div style={{ marginTop: '3px', marginLeft: '5px' }}>
                                                    <IconButton onClick={e => handleClose(e, item.practice_id)}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </div>

                                            </div>
                                        </div>
                                        {index !== Object.keys(selectedPracticesAndRoles).length - 1 && <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />}
                                    </React.Fragment>
                                )
                            })
                    }
                </div>
            </div>

        </>
    )
}

export default AssociateUsersPracticeRoleSelector
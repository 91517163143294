import api from '../../../service/api';
import { getStorage } from '../../../utilities/browserStorage';

const SearchPosCodes = async (query) => {
    let path = 'super-admin/service-codes/?' + 'column=name&search=' + query;
    return api.get(path);
}

const SearchTosCodes = async (query) => {
    let path = 'super-admin/service-type/?' + 'search=' + query;
    return api.get(path);
}

const ListClaimStatusSubStatusData = async (pageSize, page, practicePK) => {
    let path = null;
    path = 'practice/claim-status-sub-status/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK ;
    return api.get(path);
}

const ListClaimStatus = async (pageSize, page, practicePK, listType, patientPK, claimPK) => {
    let path = null;
    path = 'practice/claim-status/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK + '&list_type=' + listType;
    if (patientPK)
        path += '&patient_pk=' + patientPK
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
}

const ListClaimSubStatus = async (pageSize, page, practicePK, claimPK) => {
    let path = null;
    path = 'practice/claim-sub-status/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK;
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
}

const ListClaimStatusSubStatus = async (pageSize, page, practicePK, claimPK, statusPK) => {
    let path = null;
    path = 'practice/claim-status-sub-status/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK + '&status_pk=' + statusPK;
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
}


const GetListPatientDropdown = async (search) => {
    let path = null;
    path = 'patient/patients/?list_type=drop_down&page=0&practice_pk=' + getStorage('practice') + '&search=' + search;
    return api.get(path);
}

const GetModifierList = async (pageSize, page) => {
    let path = 'super-admin/modifier/?page_size=' + pageSize + '&page=' + page;
    return api.get(path);
}
const GetModifier = async (query, claimPK) => {
    let path = 'super-admin/modifier/?search=' + query;
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
}

const ListOfUSStates = async (claimPK) => {
    let path = "super-admin/us-states/?page=0";
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
};
const SaveNewClaimCopy = async (data) => {
    let path = "claim/claims/";
    return api.post(path, data);
}

const GetListPatientClaims = async (query, exportType) => {
    let path = "claim/claims/" + query;
    let result = null;
    if (exportType === 'xls')
        result = api.getXLS(path);
    else
        result = api.get(path);
    return result;
}

const GetPatientDetails = async (patientPK) => {
    let path = "patient/patient-details/" + patientPK + "?type=add_claim";
    return api.get(path);
}

const GetListScrubbedPatientClaims = async (query) => {
    let path = 'claim/claims-scrubbing/' + query;
    return api.get(path);
}

const GetListFilteredPatientClaims = async (query, exportType) => {
    let result = null;
    let path = null;
    if (exportType) {
        path = '/claim/claims-filter/' + query + '&export=true&export_type=' + exportType;
        if (exportType === 'xls')
            result = api.getXLS(path);
        else
            result = api.get(path);
        return result;
    }
    else {
        path = '/claim/claims-filter/' + query;
        result = api.get(path);
        return result;
    }
}

const ListInsuranceNames = async (pageSize, page, practicePK) => {
    let path = "practice/insurance-company/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" + practicePK + '&list=' + true;
    return api.get(path);
}
const ListInsurancePayerID = async (pageSize, page, practicePK) => {
    let path = "practice/insurance-company/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" + practicePK + '&list=' + true+'&payer_id=' + true;
    return api.get(path);
}
const ListNotesTypes = async (pageSize, page, isAdminModule, practicePK, claimPK) => {
    let path = null;
    if (isAdminModule === 'false')
        path = 'practice/note-type/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK;
    else
        path = 'super-admin/note-type/?page_size=' + pageSize + '&page=' + page;
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);
}
const SaveNewClaimNotes = async (data) => {
    let path = "claim/notes/";
    return api.post(path, data);
}
const ListClaimNotes = async (claim_pk, pageSize, page) => {
    let path = "claim/notes/?category=claim&claim_pk=" + claim_pk + '&page_size=' + pageSize + '&page=' + page;
    return api.get(path);
}
const GetClaimNotes = async (id) => {
    let path = "/claim/notes/" + id;
    return api.get(path);
}

const UpdateClaimNotes = async (id, data) => {
    let path = "claim/notes/" + id;
    return api.put(path, data);
}
const DeleteClaimNotes = async (id) => {
    let path = "claim/notes/" + id;
    return api.destroy(path);
}
const ListFacilityLocations = async (pageSize, page, practicePK) => {
    let path = "claim/facility-locations/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" + practicePK;
    return api.get(path);
}

const GetListARPatientClaims = async (query) => {
    let path = '/claim/ar-claims/' + query;
    return api.get(path);
}
const ExportARPatientClaims = async (query, type) => {
    let path = '/claim/ar-claims/' + query + '&export=true&export_type=' + type;
    let result = null;
    if (type === 'xls')
        result = api.getXLS(path);
    else
        result = api.get(path);
    return result;
}
const GetGeneralClaim = async (claimPK) => {
    let path = 'claim/claims/' + claimPK;
    return api.get(path);
}
const GetBillingInfoClaim = async (biilingPK) => {
    let path = 'claim/billing-info/' + biilingPK;
    return api.get(path);
}
const GetServiceLineClaim = async (claimPK) => {
    let path = 'claim/professional-service-lines/' + claimPK;
    return api.get(path);
}
const GetPatientServiceLineClaim = async (patient_pk) => {
    let path = 'claim/patient-professional-service-lines/?patient=' + patient_pk;
    return api.get(path);
}
const GetAdditionalClaim = async (claimPK) => {
    let path = 'claim/professional-additional-claim-info/' + claimPK;
    return api.get(path);
}
const UpdateNewClaim = async (id, data) => {
    let path = '/claim/claims/' + id;
    return api.put(path, data);
}

const GetListPatientPaperClaims = async (query, exportType) => {
    let result = null;
    let path = null;
    if (exportType) {
        path = '/claim/paper-claims/' + query + '&export=true&export_type=' + exportType;
        if (exportType === 'xls')
            result = api.getXLS(path);
        else
            result = api.get(path);
        return result;
    }
    else {
        path = '/claim/paper-claims/' + query;
        result = api.get(path);
        return result;
    }
}
const GetSelectedTabs = async (type, practice_pk) => {
    let path = 'user/user-opened-tab/?type=' + type + '&practice_pk=' + practice_pk;
    return  api.get(path);
}

const AddRemoveSelectedTab = async (data) => {
    let path = 'user/user-opened-tab/';
    return api.post(path, data);
}

const GetListPayerInfo = async (pageSize, page, patient_pk, priority,claim_pk) => {
    let path = 'claim/payer-info-list/?list=true&page_size=' + pageSize + '&page=' + page + '&patient_pk=' + patient_pk + '&claim_pk=' + claim_pk;
    if (priority) path += '&priority=' + priority;
    return api.get(path);
}
const ServiceLocationLists = async (practice_pk, patient_pk, claim_pk, no_default) => {
    let path = 'claim/service-locations/?practice_pk=' + practice_pk;
    if (patient_pk) path += '&opened_patient_pk=' + patient_pk;
    if (claim_pk) path += '&claim_pk=' + claim_pk;
    if (no_default) path += '&no_default=' + no_default;
    return api.get(path);
}

const GetPracticeBasicData = async (practicePK) => {
    let path = 'practice/get-practice-basic-data/' + practicePK;
    return api.get(path);
}

const UpdateClaimActive = async (claimPK, data) => {
    let path = 'claim/claim-activate/' + claimPK;
    return api.put(path, data);

};

const GetFacilityPos = async (serviceLocationPk) => {
    let path = `claim/service-location-pos/?service_location_id=${serviceLocationPk}&facility_type=facility`
    return api.get(path);
}

const GetCPTCodeList = async (pageSize, page, practicePK, type, search, claimPK,dos) => {
    let path = "practice/cpt-codes/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" + practicePK + "&search=" + search;
    if (type) {
        path += "&type=" + type
    }
    if (dos) {
        path += '&dos=' + dos;
    }
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return  api.get(path);

}

const GetICD10List = async (pageSize, page, practicePK, type, search, claimPK) => {
    let path = "practice/icd10/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" + practicePK + "&search=" + search;
    if (type) {
        path += "&type=" + type
    }
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
        return api.get(path);
}

const GetIdentifiers = async (patientPK, searchType, provider) => {
    let path = "claim/get-identifiers/?patient_id=" + patientPK + "&type=" + searchType + '&provider_id=' + provider;
    return api.get(path);
}

const ListCPTCodes = async (practicePK) => {
    let path = 'practice/cpt-codes/?type=dropdown&page=0&practice_pk=' + practicePK;
    return api.get(path);
}

const UpdateClaimStatusbulk = async (data) => {
    let path = 'claim/claim-bulk-status-update/';
    return api.post(path, data);
}

const GetClaimWarnings = async (claimID) => {
    let path = 'claim/get-claim-warnings/' + claimID;
    return api.get(path);
}

const ClaimBulkWarningCheck = async (data) => {
    let path = 'claim/claim-bulk-warnings-check/';
    return  api.post(path, data);
}

const GetPatientDXCPTDetails = async (patientPK) => {
    let path = 'patient/patient-dx-cpt-data/?patient_pk=' + patientPK;
    return api.get(path);
}

const GetPatientAccidentInfo = async (patientPK) => {
    let path = 'patient/patient-accident-info/?patient_pk=' + patientPK;
    return api.get(path);
}

const GetPatientHistory=async (patientPK)=>{
    let path='claim/patient-professional-service-lines/?patient='+patientPK;
    return api.get(path);
}

const GetPaperClaimFormPDF = async (query) => {
    let path = 'claim/paper-claim-pdf/?' + query;
    return api.getXLS(path);
}

const GenerateHCFA = async (data) => {
    let path = 'claim/paper-claim-billing/'
    return api.post(path,data);
}

const ImportClaim = async (data) => {
    let path = 'claim/import-claim/'
    return api.post(path, data);
}

const FacilityPOS = async (facilityPK) => {
    let path = 'claim/facility-pos/' + facilityPK;
    return  api.get(path);
}

export default {
    SearchPosCodes,SearchTosCodes,
    ListClaimStatus, ListClaimSubStatus, ListClaimStatusSubStatus, GetListPatientDropdown, GetModifierList, GetModifier, ListOfUSStates,ListInsurancePayerID,
    SaveNewClaimCopy, GetListPatientClaims, GetPatientDetails, GetListScrubbedPatientClaims, GetListFilteredPatientClaims,
    ListInsuranceNames, ListFacilityLocations, GetListARPatientClaims, SaveNewClaimNotes, UpdateClaimNotes, DeleteClaimNotes, GetClaimNotes,
    ListClaimNotes, GetGeneralClaim, GetServiceLineClaim, GetAdditionalClaim, UpdateNewClaim, ListNotesTypes, GetListPatientPaperClaims,
    GetSelectedTabs, AddRemoveSelectedTab, GetBillingInfoClaim, ServiceLocationLists, GetListPayerInfo, GetPracticeBasicData,
    UpdateClaimActive, GetCPTCodeList, GetICD10List, GetIdentifiers, ListCPTCodes, UpdateClaimStatusbulk, GetClaimWarnings,GetPatientHistory,
    ClaimBulkWarningCheck, GetPatientDXCPTDetails, GetPatientAccidentInfo, GetPaperClaimFormPDF, ImportClaim, FacilityPOS, ExportARPatientClaims,
    GetPatientServiceLineClaim,GetFacilityPos,ListClaimStatusSubStatusData,GenerateHCFA
}

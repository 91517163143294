import i18n from "../../../utilities/i18n";

const commonWidths = {
    small: 'small',
    large: 'large',
    extraSmall: 'extra-small'
};


const tableData = [
    {
        "head": {
            name: i18n.t("dictionariesPages.chargePanels.labels.panelCode"),
            type: "string",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "panel_code",
            value: "",
            type: "string",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("dictionariesPages.chargePanels.labels.panelName"),
            type: "string",
            width: commonWidths.large,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "panel_name",
            value: "",
            type: "string",
            align: "center",
            width: commonWidths.large,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("dictionariesPages.action"),
            type: "string",
            width: "action_column",
        },
        "body": {
            "id": "",
            "name": "name",
            "value": "",
            "type": "plusButton",
            "align": "center",
            "width": "small",
        },
    }
]

export const ClaimChargePanelsTable = {
    name: "claim-charge-panels-table",
    isResize: false,
    isFixedHead: true,
    tableHeadings: tableData.map(item => item.head),
    tableBodyData: [tableData.map(item => item.body)],
};
import i18n from "../../../utilities/i18n";

const commonWidths = {
    small: 'small',
    large: 'large',
    extraSmall: 'extra-small'
};


const tableData = [
    {
        "head": {
            name: i18n.t("dictionariesPages.chargePanels.labels.panelCode"),
            type: "string",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "panel_code",
            value: "",
            type: "string",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("dictionariesPages.chargePanels.labels.panelName"),
            type: "string",
            width: commonWidths.large,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "panel_name",
            value: "",
            type: "string",
            align: "center",
            width: commonWidths.large,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("dictionariesPages.action"),
            type: "string",
            width: "action_column",
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "action",
            value: "",
            type: "button",
            align: "center",
            width: "action_column",
            hideValue: false,
            "dropdown": [
                {
                    'name': 'Edit'
                }
            ]
        },
    }
]

export const ChargePanelMasterTable = {
    name: "charge-panel-master-table",
    isResize: false,
    isFixedHead: true,
    tableHeadings: tableData.map(item => item.head),
    tableBodyData: [tableData.map(item => item.body)],
};


const procedureTableData = [
    {
        "head": {
            name: i18n.t("searchClaims.professionalClaims.serviceLine.cpt"),
            type: "string",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "cpt_name",
            value: "",
            type: "number",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("searchClaims.professionalClaims.serviceLine.fee"),
            type: "string",
            width: commonWidths.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "fee",
            value: "",
            type: "currency",
            align: "center",
            width: commonWidths.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("searchClaims.professionalClaims.serviceLine.units"),
            type: "number",
            width: commonWidths.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "fee_units",
            value: "",
            type: "number",
            align: "center",
            width: commonWidths.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("searchClaims.professionalClaims.serviceLine.charges"),
            type: "string",
            width: commonWidths.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "charges",
            value: "",
            type: "currency",
            align: "center",
            width: commonWidths.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("searchClaims.professionalClaims.serviceLine.pos"),
            type: "string",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "pos_name",
            value: "",
            type: "string",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("searchClaims.professionalClaims.serviceLine.tos"),
            type: "string",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "tos_name",
            value: "",
            type: "string",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: "Action",
            type: "string",
            width: "action_column",
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "action",
            value: "",
            type: "button",
            align: "center",
            width: "action_column",
            hideValue: false,
            "dropdown": [
                {
                    'name': 'Edit'
                },
                {
                    'name': 'Delete'
                }
            ]
        },
    }
];

export const PanelProceduresTable = {
    name: "panel-procedures-table",
    isResize: false,
    isFixedHead: true,
    tableHeadings: procedureTableData.map(item => item.head),
    tableBodyData: [procedureTableData.map(item => item.body)],
};
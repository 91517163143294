// src/worker/sharedWorkerManager.js
class TokenSharedWorkerManager {
    constructor() {
        if (!TokenSharedWorkerManager.instance) {
            this.worker = new SharedWorker('/tokenSharedWorker.js', { name: 'activityWorker' });

            this.listeners = new Set();
            this.worker.port.onmessage = (event) => {
                this.listeners.forEach((listener) => listener(event.data));
            };

            TokenSharedWorkerManager.instance = this;
        }

        return TokenSharedWorkerManager.instance;
    }

    postMessage(message) {
        if (this.worker && this.worker.port) {
            this.worker.port.postMessage(message);
        } else {
            console.warn('SharedWorker not initialized');
        }
    }

    setActivity(timestamp) {
        this.postMessage({
            type: 'activity',
            payload: timestamp,
        });
    }

    setTokens(tokenData) {
        this.postMessage({
            type: 'setToken',
            payload: tokenData,
        })
    }

    setValue(key, value) {
        this.postMessage({
            type: 'set',
            payload: { key, value },
        });
    }

    clearStorage() {
        this.postMessage({
            type: 'clear',
        });
    }

    addMessageListener(listener) {
        this.listeners.add(listener);
    }

    removeMessageListener(listener) {
        this.listeners.delete(listener);
    }
}

const workerManager = new TokenSharedWorkerManager();
Object.freeze(workerManager);
export default workerManager;

import api from '../../service/api'

const UserLogin = async(data) =>{
	let path = '/login/'
	return api.login(path,data);
}

const SendForgotPasswordLink = async(data) =>{
	let path = '/user/forgot-password/'
	return api.postWithoutJWT(path,data);
}

const renewTokenAPI=async(data)=>{
	let path = '/user/token/refresh'
	return api.renewToken(path,data)
}

export default {UserLogin, SendForgotPasswordLink,renewTokenAPI};
import i18n from "../../../utilities/i18n";

const TableHeadData = [
    {
        "name": "checkbox",
        "type": "checkbox",
        "width": "check_box",
        "sort": false,
        "value": false,
    },
    {
        "name": i18n.t("paperClaims.claimId"),
        "type": "number",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
    },
    {
        "name": i18n.t("paperClaims.dos"),
        "type": "currency",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
    },
    {
        "name": i18n.t("paperClaims.patientName"),
        "type": "string",
        "width": "medium",
        "sort": true,
        "initialOrdering": true,
    }, 
    {
        "name": i18n.t("paperClaims.payerName"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("paperClaims.charges"),
        "type": "currency",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("paperClaims.payments"),
        "type": "currency",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("paperClaims.adjustments"),
        "type": "currency",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("paperClaims.balance"),
        "type": "currency",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("paperClaims.claimStatus"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("paperClaims.previewHCFA"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
   
]; 

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "checkbox",
            "type": "checkbox",
            "width": "check_box",
            "align": "center",
            "value": false
        },
        {
            "id": "",
            "name": "custom_claim_id",
            "value": "",
            "type": "string",
            "needLink": true,
            "align": "center",
            "width": "small",
        }, 
        {
            "id": "",
            "name": "dos_from",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
        },   
        {
            "id": "",
            "name": "patient_name",
            "value": "",
            "type": "string",
            "needLink": true,
            "align": "left",
            "width": "small",
        }, 
        {
            "id": "",
            "name": "payer_name",
            "value": "",
            "type": "responsiblePayer",
            "align": "left",
            "width": "medium",
        },   
        {
            "id": "",
            "name": "payer_types",
            "value": "",
            "type": "payer_types",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "charges",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },
        {
            "id": "",
            "name": "payments",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },   
        {
            "id": "",
            "name": "adjustments",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },
        {
            "id": "",
            "name": "balance",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },
        {
            "id": "",
            "name": "claim_status_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        }, 
        {
            "id": "",
            "name": "exportOptionsHCFA",
            "value": [],
            "type": "exportOptionsHCFA",
            "align": "center",
            "width": "small",
        },
       
    ]
]

export const PaperClaimsTableData = {
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}
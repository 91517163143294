// React core imports
import React, { useState, useEffect } from 'react';

// Third-party library imports
import { Form } from 'react-bootstrap';
import { Checkbox, FormControlLabel } from '@mui/material';

// Local component imports
import CustomizedDialogs from '../../../modalWindowComponent/CustomizedDialogs';
import TextInput from '../../../commons/input/input';

// Utilities and services
import i18n from '../../../../utilities/i18n';
import service from '../service';
import { getStorage } from '../../../../utilities/browserStorage';

/**
 * @typedef {Object} PanelFormData
 * @property {string} id - The unique identifier of the panel
 * @property {string} panel_name - The name of the panel
 * @property {string} panel_code - The code identifier of the panel
 * @property {boolean} active - The active status of the panel
 */

/**
 * @typedef {Object} PanelCreateUpdateModalProps
 * @property {('create'|'update')} action - The action to be performed
 * @property {Function} showNotifyWindow - Function to toggle notify component with message
 * @property {Function} setShowLoadingBar - Function to toggle loading bar
 * @property {Function} handleSuccess - Callback for successful addition or update
 * @property {boolean} showModal - Controls modal visibility
 * @property {Function} onClose - Handler for modal close
 * @property {PanelFormData} [initialData] - Initial data for update mode
 */

// Initial state for the panel code form
const INITIAL_FORM_STATE = {
    id: '',
    panel_name: '',
    panel_code: '',
    active: false,
};

/**
 * Modal component for creating and updating charge panels
 * @param {PanelCreateUpdateModalProps} props - Component props
 * @returns {React.ReactElement} Panel create/update modal component
 */
const PanelCreateUpdateModal = ({
    action,
    showNotifyWindow,
    setShowLoadingBar,
    handleSuccess,
    showModal,
    onClose,
    initialData
}) => {
    // Local form state
    const [formData, setFormData] = useState(INITIAL_FORM_STATE);

    // Handle form data initialization and reset based on modal state
    useEffect(() => {
        if (showModal) {
            // Initialize form with data when in update mode
            if (action === 'update' && initialData) {
                setFormData(initialData);
            }
        } else {
            // Reset form when modal closes
            setFormData(INITIAL_FORM_STATE);
        }
    }, [showModal, action, initialData]);

    /**
     * Handles form input changes
     * @param {React.ChangeEvent<HTMLInputElement>} e - Input change event
     */
    const handleInputChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData(prev => ({
            ...prev,
            [e.target.name]: value
        }));
    };

    /**
     * Validates form data
     * @returns {boolean} True if form is valid
     */
    const isFormValid = () => {
        // Check if required fields are filled
        if (!formData.panel_code.trim() || !formData.panel_name.trim()) {
            showNotifyWindow('show', 'error', i18n.t("dictionariesPages.chargePanels.labels.panelAddFormValidation"));
            return false;
        }

        // Check if panel code is exactly 5 characters
        if (formData.panel_code?.trim()?.length !== 5) {
            return false;
        }

        return true;
    };

    /**
     * Prepares payload for API request
     * @returns {Object} Formatted payload
     */
    const preparePayload = () => {
        const payload = {
            panel_code: formData.panel_code?.trim(),
            panel_name: formData.panel_name?.trim(),
            practice: getStorage('practice'),
        };

        if (action === 'update') {
            payload.active = formData.active;
            delete payload.practice;
        }

        return payload;
    };

    /**
     * Handles form submission
     * @param {React.FormEvent} e - Form submission event
     */
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Validate form before proceeding
        if (!isFormValid()) {
            return;
        }

        setShowLoadingBar(true);

        try {
            const payload = preparePayload();
            let response;

            if (action === 'create') {
                response = await service.AddNewChargePanel(payload);
            } else if (action === 'update') {
                response = await service.UpdateChargePanel(formData.id, payload);
            }

            if (response.status !== 201 && response.status !== 200) {
                throw new Error(response.data?.message || i18n.t('errorMessages.commonError'));
            }

            const successMessage = action === 'update'
                ? i18n.t("dictionariesPages.chargePanels.labels.panelUpdateSuccess")
                : i18n.t("dictionariesPages.chargePanels.labels.panelAddSuccess");

            showNotifyWindow('show', 'success', successMessage);
            handleSuccess(response.data);
            onClose();
            setFormData(INITIAL_FORM_STATE);

        } catch (error) {
            const errorMessage = error.message === "charge_panel_already_exists"
                ? i18n.t("dictionariesPages.chargePanels.labels.panelAlreadyExists")
                : error.message === "charge_panel_already_exists_but_inactive"
                    ? i18n.t("dictionariesPages.chargePanels.labels.panelAlreadyExistsButInactive")
                    : error.message;

            showNotifyWindow('show', 'error', errorMessage);
        } finally {
            setShowLoadingBar(false);
        }
    };

    /**
     * Renders the form fields
     * @returns {React.ReactElement} Form fields component
     */
    const renderFormFields = () => (
        <Form.Group>
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group padding-top15">
                        <TextInput
                            type="text"
                            id="panel-code"
                            name="panel_code"
                            label={i18n.t("dictionariesPages.chargePanels.labels.panelCode")}
                            onValueChange={(e) => {
                                // Limit input to 5 characters
                                const value = e.target.value.slice(0, 5);
                                handleInputChange({
                                    target: {
                                        name: 'panel_code',
                                        value: value
                                    }
                                });
                            }}
                            error={!formData.panel_code || formData.panel_code.length < 5 ? true : false}
                            value={formData.panel_code}
                            required
                            maxLength={5}
                            helperText={formData.panel_code && formData.panel_code.length < 5 ?
                                "Panel code must be exactly 5 characters" :
                                "Enter exactly 5 characters"}
                        />
                    </div>
                </div>

                <div className="col-md-8">
                    <div className="form-group padding-top15">
                        <TextInput
                            type="text"
                            id="panel-name"
                            name="panel_name"
                            label={i18n.t("dictionariesPages.chargePanels.labels.panelName")}
                            onValueChange={handleInputChange}
                            className={!formData.panel_name ? "input-error" : ""}
                            value={formData.panel_name}
                            required
                        />
                    </div>
                </div>

                {action === 'update' && (
                    <div className="col-md-4 ml-2">
                        <div className="form-group pl-2 padding-top15">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.active}
                                        onChange={handleInputChange}
                                        name="active"
                                        color="primary"
                                    />
                                }
                                label={i18n.t("dictionariesPages.chargePanels.labels.activeStatus")}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Form.Group>
    );

    return (
        <CustomizedDialogs
            showModal={showModal}
            type="save"
            header={i18n.t(`dictionariesPages.chargePanels.labels.${action}HelperText`)}
            setShowModalWindow={onClose}
            onHide={onClose}
        >
            <Form id="form_dataEntry" onSubmit={handleFormSubmit} encType="multipart/form-data">
                {renderFormFields()}
            </Form>
        </CustomizedDialogs>
    );
};

export default PanelCreateUpdateModal;
// React and core dependencies
import React from 'react';
import { DateTime } from 'luxon';

// Material-UI Components and Icons
import { Box, Typography, Chip, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';

// Application utilities and theme
import i18n from '../../../../utilities/i18n';
import theme from '../../../../theme';

// Styles
import '../style.css';

/**
 * @typedef {Object} PanelData
 * @property {string} id - Panel ID
 * @property {string} panel_name - Name of the panel
 * @property {string} panel_code - Code of the panel
 * @property {boolean} active - Active status
 * @property {string} created_on - Creation date
 */

/**
 * PanelBasicDetails Component
 * @param {Object} props
 * @param {PanelData} props.panelData - Panel data to display
 * @param {Function} props.onEdit - Callback function when edit button is clicked
 * @returns {React.ReactElement}
 */
function PanelBasicDetails({ panelData, onEdit }) {
    const formatDate = (date) => {
        if (!date) return 'N/A';
        return DateTime
            .fromISO(date)
            .setZone('America/New_York')
            .toFormat('MM/dd/yyyy, hh:mm a');
    };

    return (
        <Box
            className="panel-basic-details"
            sx={{ maxWidth: { xs: '100%', md: '40%' } }}
        >
            <Box className="panel-header">
                <Typography
                    variant="h6"
                    className="font-bold panel-title"
                >
                    {i18n.t("dictionariesPages.chargePanels.headers.basicDetails")}
                </Typography>

                <IconButton
                    onClick={onEdit}
                    className="edit-button"
                    aria-label="edit basic details"
                >
                    <CreateRoundedIcon
                        fontSize='medium'
                        sx={{ color: theme.palette.primary.main }}
                    />
                </IconButton>
            </Box>

            <Box className="detail-container">

                <Box className="detail-row">
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        className="detail-label"
                    >
                        {i18n.t("dictionariesPages.chargePanels.labels.panelCode")}
                    </Typography>
                    <Typography variant="body1" className="font-semibold">
                        {panelData?.panel_code || 'N/A'}
                    </Typography>
                </Box>

                <Box className="detail-row">
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        className="detail-label"
                    >
                        {i18n.t("dictionariesPages.chargePanels.labels.panelName")}
                    </Typography>
                    <Typography
                        variant="body1"
                        className="font-semibold panel-name-text"
                        title={panelData?.panel_name || 'N/A'}
                    >
                        {panelData?.panel_name || 'N/A'}
                    </Typography>
                </Box>

                <Box className="detail-row">
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        className="detail-label"
                    >
                        {i18n.t("dictionariesPages.chargePanels.labels.status")}
                    </Typography>
                    <Chip
                        icon={panelData?.active ? <CheckCircleIcon /> : <CancelIcon />}
                        label={panelData?.active ?
                            i18n.t("dictionariesPages.chargePanels.labels.activeStatus") :
                            i18n.t("dictionariesPages.chargePanels.labels.inactiveStatus")
                        }
                        color={panelData?.active ? 'success' : 'default'}
                        variant="outlined"
                        size="small"
                    />
                </Box>

                <Box className="detail-row">
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        className="detail-label"
                    >
                        {i18n.t("dictionariesPages.chargePanels.labels.createdOn")}
                    </Typography>
                    <Typography variant="body1" className="font-semibold">
                        {formatDate(panelData?.created_on)}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default PanelBasicDetails;

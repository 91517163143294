
import { useCallback, useEffect, useState } from 'react';
import workerManager from '../components/authentications/tokenWorkerManager';

/**
 * Monitors user activity with the least possible performance impact
 * @param {number} throttleInterval - Milliseconds to throttle activity updates (default: 2000ms)
 * @param {function} onActivity - Callback triggered when user activity is detected
 * @returns {function} - Cleanup function to remove listeners
 */
function monitorUserActivity({
    throttleInterval = 2000,
    onActivity
} = {}) {
    let lastUpdateTime = 0;

    const handleActivity = () => {
        const now = Date.now();
        // Manual throttling: only call onActivity if throttleInterval has passed
        if (now - lastUpdateTime >= throttleInterval) {
            lastUpdateTime = now;
            onActivity && onActivity(now);
        }
    };

    // Use minimal set of events to reduce performance impact
    // These events are most indicative of active user interaction
    const activityEvents = ['click', 'keydown', 'touchstart', 'mousemove'];

    // Add passive listeners for better performance
    activityEvents.forEach((eventName) => {
        document.addEventListener(eventName, handleActivity, { passive: true });
    });

    // Return cleanup function
    return () => {
        activityEvents.forEach((eventName) => {
            document.removeEventListener(eventName, handleActivity);
        });
    };
}

/**
 * Broadcasts activity status to a shared worker if available
 * This can be used for cross-tab synchronization of activity state
 * @param {number} timestamp - The timestamp of the activity (currently unused but preserved for future implementation)
 */
async function broadcastActiveToSharedWorker(timestamp) {
    try {
        // Implementation for communicating with a SharedWorker
        if (window.SharedWorker) {
            workerManager.setActivity(Date.now());
            // Uncomment the above code when implementing the SharedWorker
            void (timestamp); // Acknowledge parameter to prevent linter warning
        }

        // For now, just log silently (will be replaced with actual implementation)
    } catch (error) {
        // Silent error handling to avoid console pollution
    }
}

/**
 * React hook to monitor user activity with minimal performance overhead
 * Only starts tracking when enabled and only broadcasts activity to shared worker
 * @param {Object} options - Configuration options
 * @param {boolean} options.enabled - Whether activity tracking is enabled
 * @param {number} options.throttleInterval - Milliseconds to throttle activity updates (default: 2000ms)
 * @returns {void} - This hook doesn't return anything as it just broadcasts activity
 */
function useUserActivity() {
    const [isTracking, setIsTracking] = useState(true); 

    const toggleTracking = useCallback(() => {
        setIsTracking((prev) => !prev);
    }, []);
    useEffect(() => {
        if (!isTracking) return;
        // Set up activity monitoring with direct broadcasting
        const cleanup = monitorUserActivity({
            throttleInterval: 2000,
            onActivity: broadcastActiveToSharedWorker
        });
        return cleanup;
    }, [isTracking]);

    return { toggleTracking };
}

export default useUserActivity;
import { YesOrNo } from "../../../utilities/dictionaryConstants";
import { DEFAULT_AND_CUSTOM_OPTIONS } from "../../../utilities/staticConfigs";

import procedureService from "../../dictionaries/CPTCodes/service";
import panelService from "./service";

// Find the "Custom" option ID using the name for better readability
export const CUSTOM_OPTION_ID = DEFAULT_AND_CUSTOM_OPTIONS.find(option => option.name === "Custom")?.id;
export const DEFAULT_OPTION_ID = DEFAULT_AND_CUSTOM_OPTIONS.find(option => option.name === "Default")?.id;
export const DEFAULT_NO_ID = YesOrNo.find(option => option.name === "No")?.id;
const DRUG_CODE_LENGTH = 11;
export const DEBOUNCE_LIMIT = 500;


// Utility functions
export const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
    };
};



/**
 * Represents the state and transformation logic for a procedure form.
 * Manages initialization, updates, and fetching of default procedure data.
 */
export class ProcedureFormState {
    /**
     * Initializes the default state of the procedure form with empty or null values.
     */
    constructor() {
        this.initializeDefaultState();
    }

    /**
     * Helper method to initialize the default state of the form.
     * Separated for better readability and maintainability.
     */
    initializeDefaultState() {
        this.panelProcedureId = null;
        this.cpt_hcpcs = null;
        this.pos = null;
        this.tos = null;
        this.modifier_option = DEFAULT_OPTION_ID; // 1 = Default, 2 = Custom
        this.m1 = null;
        this.m2 = null;
        this.m3 = null;
        this.m4 = null;
        this.fee_option = DEFAULT_OPTION_ID; // 1 = Default, 2 = Custom
        this.fee = null;
        this.fee_units = null;
        this.total_charges = null;
        this.emg = DEFAULT_NO_ID; // Default emergency value
        this.proced_description = "";
        this.line_note = "";
        this.anesthesia = "";
        this.drug_code = "";
        this.drug_code_valid = true;
        this.drug_unit_type = null;
        this.drug_unit_value = null;
        this.measurement_unit_type = null;
        this.measurement_unit_value = "";
        this.charge_panel = null;
        this.cpt_epsdt = DEFAULT_NO_ID;
    }

    /**
     * Formats modifier values into a consistent structure.
     * @param {Object|Object[]|null} value - The modifier value (single object, array, or null).
     * @returns {Object|null} Formatted modifier object or null if invalid.
     */
    formatModifierValue(value) {
        if (!value || !Array.isArray(value) || value.length === 0) {
            return null;
        }

        const [modifier] = value;
        return {
            id: modifier.id,
            name: modifier.drop_down_name || '',
            label: modifier.drop_down_name || ''
        };
    }

    /**
     * Calculates total charges based on fee and units.
     * @param {string|number|null} fee - The fee value.
     * @param {string|number|null} units - The units value.
     * @returns {number|null} Calculated total charges or null if invalid.
     */
    calculateTotalCharges(fee, units) {
        const parsedFee = parseFloat(fee);
        const parsedUnits = parseFloat(units);

        if (isNaN(parsedFee) || isNaN(parsedUnits)) {
            return null;
        }

        return Math.round(parsedFee * parsedUnits * 100) / 100;
    }

    /**
     * Updates the form state with new procedure data.
     * @param {Object|null} newProcedureData - The new procedure data to populate the form state.
     * @returns {ProcedureFormState} The updated instance of the form state.
     */
    updateWithNewProcedureData(newProcedureData) {
        if (!newProcedureData) return this;

        const data = newProcedureData;

        // Reset and set basic fields
        this.panelProcedureId = null;
        this.cpt_hcpcs = data ? {
            id: data.id,
            name: data.name || '',
            description: data.description || '',
            drop_down_name: data.drop_down_name || ''
        } : null;
        this.proced_description = data?.proced_description || "";
        this.fee = data?.fee ?? null;
        this.fee_units = data?.units ?? null;
        this.fee_option = DEFAULT_OPTION_ID;

        // Set Place of Service (POS)
        this.pos = data?.pos ? {
            id: data.pos.id,
            name: data.pos.name || '',
            label: data.pos.label || ''
        } : null;

        // Set Type of Service (TOS)
        this.tos = data?.tos ? {
            id: data.tos.id,
            name: data.tos.name || '',
            label: data.tos.name || ''
        } : null;

        // Set modifier option and modifiers
        this.modifier_option = DEFAULT_OPTION_ID;
        this.m1 = this.formatModifierValue(data?.m1_value);
        this.m2 = this.formatModifierValue(data?.m2_value);
        this.m3 = this.formatModifierValue(data?.m3_value);
        this.m4 = this.formatModifierValue(data?.m4_value);

        // Calculate total charges
        this.total_charges = this.calculateTotalCharges(data?.fee, data?.units);

        return this;
    }

    /**
     * Fetches default values for a procedure based on the given procedure ID.
     * @param {string|number} procedureId - The ID of the procedure to fetch data for.
     * @returns {Promise<Object|null>} 
     */
    async fetchProcedureDefaultValues(procedureId) {
        if (!procedureId) {
            console.error('Invalid procedureId: procedureId must be provided.');
            return null;
        }

        try {
            const response = await procedureService.getCptCode("practice", procedureId);
            if (!response?.data) {
                throw new Error("No data returned from procedure service.");
            }

            const data = response.data;
            const totalCharges = this.calculateTotalCharges(data?.default_fee, data?.default_units);

            return {
                modifier_option: DEFAULT_OPTION_ID,
                m1: this.formatModifierValue(data?.m1List),
                m2: this.formatModifierValue(data?.m2List),
                m3: this.formatModifierValue(data?.m3List),
                m4: this.formatModifierValue(data?.m4List),
                fee: data?.default_fee ?? null,
                fee_units: data?.default_units ?? null,
                fee_option: DEFAULT_OPTION_ID,
                total_charges: totalCharges
            };
        } catch (error) {
            console.error('Error fetching procedure default values:', error.message);
            return null;
        }
    }

    /**
     * Fetches and updates the form state with saved panel procedure data.
     * @param {string|number} panelProcedureId - The ID of the panel procedure to fetch.
     * @returns {Promise<ProcedureFormState>} A promise resolving to the updated instance of the form state.
     */
    async getSavedPanelProcedure(panelProcedureId) {
        if (!panelProcedureId) {
            throw new Error("Invalid panelProcedureId: panelProcedureId must be provided.");
        }

        try {
            const response = await panelService.GetPanelProcedure(panelProcedureId);
            if (!response || !response.data) {
                throw new Error("An error occurred while retrieving the procedure data.");
            }

            const data = response.data;
            this.panelProcedureId = data.id ?? null;
            this.charge_panel = data.charge_panel ?? null;

            // Set CPT/HCPCS code
            this.cpt_hcpcs = data.cpt_hcpcs ? {
                id: data.cpt_hcpcs.id,
                name: data.cpt_hcpcs.name || '',
                description: data.cpt_hcpcs.description || '',
                drop_down_name: data.cpt_hcpcs.drop_down_name || ''
            } : null;

            // Set Place of Service (POS) and Type of Service (TOS)
            this.pos = data.pos ? {
                id: data.pos.id,
                name: data.pos.name || '',
                label: data.pos.label || ''
            } : null;
            this.tos = data.tos ? {
                id: data.tos.id,
                name: data.tos.name || '',
                label: data.tos.name || ''
            } : null;

            // Set emergency (emg) and CPT EPSDT values
            this.emg = data.emg ?? DEFAULT_NO_ID;
            this.cpt_epsdt = data.cpt_epsdt ?? DEFAULT_NO_ID;

            // Set modifier option and modifiers
            this.modifier_option = data.modifier_option ?? DEFAULT_OPTION_ID;
            this.m1 = data.m1 ? { id: data.m1.id, name: data.m1.name || '', label: data.m1.label || '' } : null;
            this.m2 = data.m2 ? { id: data.m2.id, name: data.m2.name || '', label: data.m2.label || '' } : null;
            this.m3 = data.m3 ? { id: data.m3.id, name: data.m3.name || '', label: data.m3.label || '' } : null;
            this.m4 = data.m4 ? { id: data.m4.id, name: data.m4.name || '', label: data.m4.label || '' } : null;

            // Set fee-related fields
            this.fee_option = data.fee_option ?? DEFAULT_OPTION_ID;
            this.fee = typeof data.fee === 'number' ? data.fee : null;
            this.fee_units = typeof data.fee_units === 'number' ? data.fee_units : null;
            this.total_charges = typeof data.charges === 'number' ? data.charges : null;


            // Set description, line note, and anesthesia
            this.proced_description = data.proced_description || "";
            this.line_note = data.line_note || "";
            this.anesthesia = data.anesthesia || "";

            // Set drug-related fields
            this.drug_code = data.drug_code || "";
            const { isValid } = handleDrugCodeValidation(data.drug_code);
            this.drug_code_valid = data.drug_code ? isValid : false;
            this.drug_unit_type = data.drug_unit_type ?? null;
            this.drug_unit_value = data.drug_unit_value ?? null;

            // Set measurement-related fields
            this.measurement_unit_type = data.measurement_unit_type ?? null;
            this.measurement_unit_value = data.measurement_unit_value || "";

            return this;
        } catch (error) {
            console.error('Error fetching saved panel procedure:', error.message);
            throw error;
        }
    }
}



/**
 * Prepares the payload for panel procedure submission based on form data.
 * @param {Object} formData - The form data containing procedure details.
 * @param {number} panelId - The ID of the charge panel.
 * @returns {Object} The prepared payload for submission.
 */
export const preparePanelProcedurePayload = (formData, panelId) => {
    // Base payload with common fields
    const submitData = {
        cpt_hcpcs: formData.cpt_hcpcs?.id,
        pos: formData.pos?.id || null,
        tos: formData.tos?.id || null,
        emg: formData.emg,
        modifier_option: formData.modifier_option,
        fee_option: formData.fee_option,
        proced_description: formData.proced_description,
        line_note: formData.line_note,
        anesthesia: formData.anesthesia,
        drug_code: formData.drug_code,
        measurement_unit_type: formData.measurement_unit_type,
        measurement_unit_value: formData.measurement_unit_value,
        cpt_epsdt: formData.cpt_epsdt,
        charge_panel: panelId,
    };

    // Add fee-related fields if fee_option matches the "Custom" option
    if (formData.fee_option === CUSTOM_OPTION_ID) {
        submitData.fee = formData.fee;
        submitData.fee_units = formData.fee_units;
    }

    // Add modifier fields if modifier_option is enabled (value 1)
    if (formData.modifier_option === CUSTOM_OPTION_ID) {
        submitData.m1 = formData.m1?.id || null;
        submitData.m2 = formData.m2?.id || null;
        submitData.m3 = formData.m3?.id || null;
        submitData.m4 = formData.m4?.id || null;
    }

    // Add drug-related fields if drug_code is provided
    if (formData.drug_code) {
        submitData.drug_unit_type = formData.drug_unit_type;
        submitData.drug_unit_value = formData.drug_unit_value;
    } else {
        submitData.drug_unit_type = null;
        submitData.drug_unit_value = null;
    }

    const errors = [];

    // Validation Rules

    // Rule 1 - Required fields validation
    if (!submitData.cpt_hcpcs) {
        errors.push("CPT/HCPCS code is required.");
    }

    // Rule 2 - Fee and Fee Units required when Fee Option is Custom
    if (formData.fee_option === CUSTOM_OPTION_ID) {
        if (isNaN(submitData.fee)) {
            errors.push("Fee is required when Fee Option is Custom.");
        }
        if (isNaN(submitData.fee_units)) {
            errors.push("Fee Units are required when Fee Option is Custom.");
        }
        if (submitData.fee < 0) {
            errors.push("Fee should be greater than or equal to $0.00")
        }
    }

    // Rule 3 - Drug fields validation
    // If any drug-related field is provided, all three are required
    if (formData.drug_code || formData.drug_unit_type || formData.drug_unit_value) {
        if (!formData.drug_code) {
            errors.push("Drug Code is required when any drug-related field is provided.");
        }
        if (!formData.drug_unit_type) {
            errors.push("Drug Unit Type is required when any drug-related field is provided.");
        }
        if (!formData.drug_unit_value) {
            errors.push("Drug Unit Value is required when any drug-related field is provided.");
        }
    }

    // Rule 4 - Measurement validation
    if (formData.measurement_unit_type || formData.measurement_unit_value) {
        if (!formData.measurement_unit_type) {
            errors.push("Measurement Unit Type is required when Measurement Unit Value is provided.");
        }
        if (!formData.measurement_unit_value) {
            errors.push("Measurement Unit Value is required when Measurement Unit Type is provided.");
        }
    }


    // Return validation errors if any
    if (errors.length > 0) {
        return { errors, isValid: false };
    }

    return { payload: submitData, isValid: true };
};


/**
 * Formats a drug code into the universal format with pattern: XXXXX-XXXX-XX
 * @param {string} rawValue - The raw numeric input to format
 * @returns {string} - Formatted drug code with dashes at positions 5 and 9
 */
export const formatDrugCode = (rawValue) => {
    if (!rawValue) return '';

    // Remove any existing non-numeric characters
    const digitsOnly = rawValue.replace(/\D/g, '');

    // Split the string into parts for formatting
    const firstPart = digitsOnly.substring(0, 5);
    const secondPart = digitsOnly.substring(5, 9);
    const thirdPart = digitsOnly.substring(9, 11);

    // Build the formatted string with dashes
    let formattedValue = firstPart;

    if (firstPart.length === 5) {
        formattedValue += '-';
        formattedValue += secondPart;

        if (secondPart.length === 4) {
            formattedValue += '-';
            formattedValue += thirdPart;
        }
    }

    return formattedValue;
};


/**
 * Validates and formats a drug code to ensure it follows the XXXXX-XXXX-XX pattern
 * @param {string} value - The input value to validate and format
 * @returns {object} - Object containing the formatted value and validation status
 */
export const handleDrugCodeValidation = (value) => {
    // Remove non-digits and limit to max length
    const trimmedValue = String(value || '').replace(/[^\d]/g, '').substring(0, DRUG_CODE_LENGTH);

    // Format the value
    const formattedValue = formatDrugCode(trimmedValue);

    // Check if valid (either empty or complete with 11 digits which formats to 13 chars with dashes)
    const isValid = formattedValue.length === 0 || formattedValue.length === 13;

    return {
        formattedValue,
        isValid,
        rawValue: trimmedValue // Include the raw value for reference
    };
};
// React and React Router
import React, { useState, useEffect, useContext, memo, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

// Material-UI Components
import { Button, Typography } from '@mui/material';

// Material-UI Icons
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

// Local Components
import Notify from '../../commons/notify';
import NoPermission from '../../NoPermissionPage/NoPermission';
import PanelCreateUpdateModal from './Components/PanelCreateUpdateModal';
import PanelBasicDetails from './Components/PanelBasicDetails';

// Context and Theme
import LoadingContext from '../../../container/loadingContext';
import theme from '../../../theme';

// Services and Utilities
import service from './service';
import i18n from '../../../utilities/i18n';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';

// constants
import { ROUTE_PRACTICE_CHARGE_PANELS } from '../../../utilities/staticConfigs';
import { permission_key_values_accountsetup, super_admin_privileges, customer_admin_privilege } from '../../../utilities/permissions';
import CommonButton from '../../commons/Buttons';
import PanelProcedureInsertUpdateModal from './Components/PanelProcedureInsertUpdateModal';
import Table from '../../commons/Table/Table';
import { PanelProceduresTable } from './ChargePanelTables';

// stylesheet
import "./style.css";
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';

// Caching the table to avoid un-necessary table render
const MemoizedTable = memo(({ tableObject, dropDownFunction }) => {
    return (
        <Table
            tableObject={tableObject}
            onLinkClick={() => { }}
            dropDownFunction={dropDownFunction}
            tblRadiusClass={" table-border-radius12 "}
        />
    );
});
MemoizedTable.displayName = "MemoizedTable";




/**
 * Panel Editor Component
 * @description This component is used to edit the panel and its procedures
 * And it requires panel id to be passed in the location state
 * @returns {React.ReactElement}
 */
function PanelEditor() {
    // Permission check
    const isAdminModule = localStorage.getItem('isAdminModule') === 'true';
    const [permission] = useState(() => {
        const viewPermission = checkPermission(
            permission_key_values_accountsetup.account_setup_code_management_view,
            super_admin_privileges.super_admin_full_privilege,
            customer_admin_privilege
        );

        const addOrModifyPermission = checkPermission(
            permission_key_values_accountsetup.account_setup_code_management_add,
            permission_key_values_accountsetup.account_setup_code_management_modify,
            super_admin_privileges.super_admin_full_privilege,
            customer_admin_privilege
        );

        return { viewPermission, addOrModifyPermission };
    });

    if (isAdminModule || !permission.viewPermission) {
        return <NoPermission />
    }

    // Router hooks
    const location = useLocation();
    const history = useHistory();
    const panelId = location.state?.id;

    // Redirect if no panel ID
    if (!panelId) {
        history.push(ROUTE_PRACTICE_CHARGE_PANELS);
    }

    // State management
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);
    const [showPanelUpdateModal, setShowPanelUpdateModal] = useState(false);
    const [showProcedureInsertUpdateModal, setShowProcedureInsertUpdateModal] = useState(false);
    const [panelProcedureId, setPanelProcedureId] = useState(null);
    const [panelProceduresList, setPanelProceduresList] = useState([]);
    const [panelProcedureTableFlag, setPanelProcedureTableFlag] = useState(0);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [deleteProcedure, setDeleteProcedure] = useState({ id: null, procedureName: null });
    const [panelData, setPanelData] = useState({
        id: null,
        panel_name: null,
        panel_code: null,
        active: null,
        created_on: null,
    });

    // Show Notify Window
    const showNotifyWindow = (action, type, desc, age = 3000) => {
        if (action === 'show') {
            setTimeout(() => setShowNotify('hide'), age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    };

    /**
     * Fetch Charge Panel Data
     * @param {string} id - Panel ID to fetch
     */
    const getChargePanel = async (id) => {
        setShowLoadingBar(true);
        try {
            const response = await service.GetChargePanel(id);
            if (response.status !== 200 || !response.data?.id) {
                throw new Error('Failed to fetch charge panel');
            }
            setPanelData(response.data);
        } catch (error) {
            showNotifyWindow('show', 'error', i18n.t("errorMessages.commonError"));
            setPanelData(null);
        } finally {
            setShowLoadingBar(false);
        }
    };


    /**
     * Fetch Charge Panel Procedures
     * @param {number} id - Panel ID to fetch
     */
    const getPanelProcedures = async (id) => {
        setShowLoadingBar(true);
        try {
            const response = await service.GetPanelProceduresList(id);
            if (response.status !== 200 || !Array.isArray(response.data)) {
                throw new Error('Failed to fetch charge panel');
            }
            if (response && response.data) {
                setPanelProceduresList(response.data);
                const rowArray = commonTableBody(response.data, PanelProceduresTable.tableBodyData[0]);
                PanelProceduresTable.tableBodyData = rowArray;
                setPanelProcedureTableFlag(panelProcedureTableFlag + 1);
            } else {
                throw new Error('Failed to fetch charge panel procedures');
            }
        } catch (error) {
            showNotifyWindow('show', 'error', i18n.t("errorMessages.commonError"));
            setPanelProceduresList([]);
            const rowArray = commonTableBody([], PanelProceduresTable.tableBodyData[0]);
            PanelProceduresTable.tableBodyData = rowArray;
            setPanelProcedureTableFlag(panelProcedureTableFlag + 1);
        } finally {
            setShowLoadingBar(false);
        }
    };


    // Fetch panel data on mount
    useEffect(() => {
        if (panelId) {
            getChargePanel(panelId);
            getPanelProcedures(panelId);
        }
    }, [panelId]);


    // Handler for Panel edit button click
    const handlePanelEdit = () => {
        if (!permission.addOrModifyPermission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        setShowPanelUpdateModal(true);
    };


    // Callback for successful Panel update
    const handlePanelUpdateSuccess = (updatedData) => {
        setPanelData(updatedData);
        setShowPanelUpdateModal(false);
        showNotifyWindow('show', 'success', i18n.t("dictionariesPages.chargePanels.labels.panelUpdateSuccess"));
    };


    // Handler for Panel procedure insert button click
    const handleInsertProcedure = () => {
        if (!permission.addOrModifyPermission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        setShowProcedureInsertUpdateModal(true);
    };


    /**
     * Handle procedure insert/update modal close
     */
    const onProcedureInsertUpdateModalClose = () => {
        setShowProcedureInsertUpdateModal(false);
        setPanelProcedureId(null);
    };


    /**
     * Handle insert/update procedure success callback
     * @param {object} procedure - Procedure object
     * @async
     */
    const handleInsertUpdateProcedureSuccess = async (status, procedure) => {
        try {
            if (status === 201 && procedure) {
                const updatedPanelProceduresList = [...panelProceduresList, procedure];
                setPanelProceduresList(updatedPanelProceduresList);
                const rowArray = commonTableBody(updatedPanelProceduresList, PanelProceduresTable.tableBodyData[0]);
                PanelProceduresTable.tableBodyData = rowArray;
                setPanelProcedureTableFlag(panelProcedureTableFlag + 1);
                setShowProcedureInsertUpdateModal(false);
                setPanelProcedureId(null);
            } else if (status === 200 && procedure) {
                const updatedPanelProceduresList = panelProceduresList.map(item => item.id === procedure.id ? procedure : item);
                setPanelProceduresList(updatedPanelProceduresList);
                const rowArray = commonTableBody(updatedPanelProceduresList, PanelProceduresTable.tableBodyData[0]);
                PanelProceduresTable.tableBodyData = rowArray;
                setPanelProcedureTableFlag(panelProcedureTableFlag + 1);
                setShowProcedureInsertUpdateModal(false);
                setPanelProcedureId(null);
            }
        } catch (error) {
            console.error(error);
            await getPanelProcedures(panelId);
        }
    };


    /**
     * Handle procedure dropdown action
     * @param {number} id - Procedure ID
     * @param {string} name - Action name Delete  || Edit 
     */
    const handleProcedureDropdownAction = useCallback((id, name) => {
        if (!permission.addOrModifyPermission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }

        if (name === 'Edit') {
            setPanelProcedureId(id);
            setShowProcedureInsertUpdateModal(true);
        } else if (name === 'Delete') {
            setShowDeleteModalWindow(true);
            setDeleteProcedure({ id, procedureName: panelProceduresList.find(item => item.id === id)?.cpt_name });
        }
    }, [panelProceduresList]);


    /**
     * Handle procedure delete
     * @async
     */
    const onProcedureDelete = async () => {
        if (!deleteProcedure.id) {
            showNotifyWindow('show', 'error', i18n.t("errorMessages.invalidDeleteRequest"));
            onDeleteHide();
            return;
        }

        setShowLoadingBar(true);
        try {
            const response = await service.DeletePanelProcedure(deleteProcedure.id);

            if (response.status === 204) {
                showNotifyWindow('show', 'success', "Panel procedure deleted successfully.");
                setPanelProceduresList(prevList => prevList.filter(item => item.id !== deleteProcedure.id));
                const updatedList = panelProceduresList.filter(item => item.id !== deleteProcedure.id);
                const rowArray = commonTableBody(updatedList, PanelProceduresTable.tableBodyData[0]);
                PanelProceduresTable.tableBodyData = rowArray;
                setPanelProcedureTableFlag(prev => prev + 1);
            } else {
                throw new Error('Unexpected response status');
            }

            onDeleteHide();
        } catch (error) {
            console.error('Procedure deletion error:', error);
            const errorMessage = error.response?.data?.message ||
                "Failed to delete panel procedure.";
            showNotifyWindow('show', 'error', errorMessage);

            // Refresh the list to ensure UI consistency
            await getPanelProcedures(panelId);
        } finally {
            setShowLoadingBar(false);
        }
    };


    /**
     * Handle delete modal hide
     */
    const onDeleteHide = () => {
        setShowDeleteModalWindow(false);
        setDeleteProcedure({ id: null, procedureName: null });
    };

    return (
        <>
            <div className="col-md-12 mt-3">
                <div className="box box-content-white">
                    <Button
                        variant='text'
                        sx={{ 
                            color: `${theme.palette.primary.main} !important`,
                            '& .MuiButton-startIcon': {
                                marginRight: '4px'
                            },
                            padding: '0px !important'
                        }}
                        startIcon={<ArrowBackIosRoundedIcon fontSize='small' sx={{ color: theme.palette.primary.main }} />}
                        onClick={() => history.push(ROUTE_PRACTICE_CHARGE_PANELS)}
                    >
                        Back
                    </Button>
                    <div className="box-content">
                        <PanelBasicDetails
                            panelData={panelData}
                            onEdit={handlePanelEdit}
                        />

                        <PanelCreateUpdateModal
                            action="update"
                            showNotifyWindow={showNotifyWindow}
                            setShowLoadingBar={setShowLoadingBar}
                            handleSuccess={handlePanelUpdateSuccess}
                            showModal={showPanelUpdateModal}
                            onClose={() => setShowPanelUpdateModal(false)}
                            initialData={panelData}
                        />
                    </div>


                    <Typography
                        variant="h6"
                        className="font-bold"
                        color="#424242"
                        sx={{ mb: 2, mt: 2, pl: 2 }}
                    >
                        {i18n.t("dictionariesPages.chargePanels.headers.panelProcedures")}
                    </Typography>

                    <div className="panel-procedures-table">
                        <div
                            className="justify-right"
                            style={{ position: "relative", top: "10px", marginBottom: "15px" }}>
                            <CommonButton
                                onClick={() => { handleInsertProcedure() }}
                                variant="contained"
                                icon="add"
                                label={i18n.t("searchClaims.addProcedure")}
                            />
                        </div>
                        <MemoizedTable
                            key={panelProcedureTableFlag}
                            tableObject={PanelProceduresTable}
                            dropDownFunction={handleProcedureDropdownAction}
                        />
                    </div>
                </div>
            </div>

            <PanelProcedureInsertUpdateModal
                showModal={showProcedureInsertUpdateModal}
                setShowModal={setShowProcedureInsertUpdateModal}
                panelProcedureId={panelProcedureId}
                setPanelProcedureId={setPanelProcedureId}
                onClose={onProcedureInsertUpdateModalClose}
                setShowLoadingBar={setShowLoadingBar}
                panelId={panelId}
                onSaveSuccess={handleInsertUpdateProcedureSuccess}
                showNotifyWindow={showNotifyWindow}
            />

            <CustomizedSmallDialogs
                showModal={showDeleteModalWindow}
                header={`${i18n.t("commons.confirmDelete")} (${deleteProcedure.procedureName})`}
                type="delete"
                deleteItem={onProcedureDelete}
                onHide={onDeleteHide}
                setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("dictionariesPages.chargePanels.labels.deleteConfirmation")}
            </CustomizedSmallDialogs>

            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
        </>
    );
}

export default PanelEditor;
import api from "../../../service/api";


const CHARGE_PANEL_ENDPOINT = 'practice/charge-panels/'
const CHARGE_PANEL_PROCEDURES_ENDPOINT = 'practice/charge-panel-procedures/'


/**
 * CREATE NEW CHARGE PANEL ENTITY
 * @param {Object} data - The request parameters
 * @param {string} data.practice - Practice primary key
 * @param {string} [data.panel_code]
 * @param {string} [data.panel_name]
 */
const AddNewChargePanel = async (data) => {
    return api.post(CHARGE_PANEL_ENDPOINT, data);
}


/**
 * GET A CHARGE PANEL ENTITY
 * @param {number} [id] - charge panel - document id
 */
const GetChargePanel = async (id) => {
    return api.get(`${CHARGE_PANEL_ENDPOINT}${id}`);
}


/**
 * GET LIST OF CHARGE PANELS
 * @param {Object} data - The request parameters
 * @param {string} data.practice_pk - Practice primary key
 * @param {number} [data.page] - Page number for pagination
 * @param {number} [data.page_size] - Number of items per page
 * @param {string} [data.status] - Status filter for charge panels
 */
const ListChargePanels = async (data) => {
    const params = new URLSearchParams({
        practice: data.practice_pk,
        page: data.page?.toString(),
        page_size: data.page_size?.toString(),
        list_type: "list",
        status: data.status,
    });

    return await api.get(`${CHARGE_PANEL_ENDPOINT}?${params.toString()}`);
}


/**
 * UPDATE CHARGE PANEL ENTITY
 * @param {Object} data - The request body
 * @param {string} [data.panel_code]
 * @param {string} [data.panel_name]
 * @param {boolean} [data.active]
 */
const UpdateChargePanel = async (id, data) => {
    return api.patch(`${CHARGE_PANEL_ENDPOINT}${id}`, data);
}


/**
 * INSERT A NEW PROCEDURE TO THE PANEL
 * @param data req.payload
 */
const InsertProcedureToPanel = async (data) => {
    return api.post(`${CHARGE_PANEL_PROCEDURES_ENDPOINT}`, data)
}


/**
 * GET PROCEDURES OF A CHARGE PANEL
 * @param {number} id
 */
const GetPanelProceduresList = async (id) => {
    return api.get(`${CHARGE_PANEL_PROCEDURES_ENDPOINT}?charge_panel_id=${id}&page=0`)
} 


/**
 * GET CONFIG OF A PROCEDURE WITHING THE PANEL
 * @param {number} id 
 */
const GetPanelProcedure = async (id) => {
    return api.get(`${CHARGE_PANEL_PROCEDURES_ENDPOINT}${id}`);
}


/**
 * UPDATE PANEL PROCEDURE
 * @param {string|number} panelProcedureId
 * @param {object} panelProcedureData
 */
const UpdatePanelProcedure = async (id, panelProcedureData) => {
    return api.patch(`${CHARGE_PANEL_PROCEDURES_ENDPOINT}${id}`, panelProcedureData)
}


/**
 * DELETE A PANEL PROCEDURE
 * @param {string|number} panelProcedureId
 */
const DeletePanelProcedure = async (id) => {
    return api.destroy(`${CHARGE_PANEL_PROCEDURES_ENDPOINT}${id}`);
}


export default {
    AddNewChargePanel,
    GetChargePanel,
    ListChargePanels,
    UpdateChargePanel,
    InsertProcedureToPanel,
    GetPanelProceduresList,
    GetPanelProcedure,
    UpdatePanelProcedure,
    DeletePanelProcedure
}